import { Paper } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { BookingsResponseDto } from '@planning/app/api'
import { CellWithTitleAndSubtitle } from '@planning/components/atoms/CellWithTitleAndSubtitle'
import { emptyIndicator } from '@planning/constants'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

interface Props {
  store: IPaginatedStoreWithItems<BookingsResponseDto>
}

export const BookingsTable = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    {
      field: 'referenceNumber',
      headerName: t('bookingNo', 'Booking no.'),
      renderCell: ({ row }) => {
        return (
          <CellWithTitleAndSubtitle
            title={row.referenceNumber ?? emptyIndicator}
            subtitle={row.shippingLine ?? emptyIndicator}
          />
        )
      },
      flex: 2,
    },
    {
      field: 'identifier',
      headerName: t('carrier', 'Carrier'),
      flex: 0.75,
      renderCell: ({ row }) => (
        <CellWithTitleAndSubtitle
          title={row.carrierVisit.identifier ?? emptyIndicator}
          subtitle={`ETA: ${formatDateTime(row.carrierVisit.eta) ?? emptyIndicator}`}
        />
      ),
    },
    { field: 'units', headerName: t('units', 'Units'), flex: 0.5 },
    {
      field: 'portOfDischarge',
      headerName: t('pod', 'POD'),
      flex: 0.5,
      renderCell: ({ row }) => row.portOfDischarge ?? emptyIndicator,
    },
  ]

  return (
    <Paper variant='elevation' elevation={1} sx={{ overflow: 'hidden' }}>
      <DataGrid
        rows={store.pageItems.slice(
          store.pageSize * store.currentPageIndex,
          store.pageSize * (store.currentPageIndex + 1),
        )}
        getRowId={row =>
          `${row.referenceNumber}-${row.carrierVisit.identifier}-${row.units}-${row.carrierVisit.id}`
        }
        columns={columns}
        paginationModel={{ pageSize: store.pageSize, page: store.currentPageIndex }}
        rowCount={store.pageItems.length}
        onPaginationModelChange={(model: { page: number; pageSize: number }) => {
          if (store.currentPageIndex !== model.page) store.setCurrentPageIndex(model.page)
          if (store.pageSize !== model.pageSize) store.setPageSize(model.pageSize)
        }}
        disableColumnFilter
        disableColumnMenu
        onRowClick={({ row }) => {
          navigate(`/bookings/${row.referenceNumber}/visit/${row.carrierVisit.id}/orders`)
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </Paper>
  )
})
