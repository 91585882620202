import { Box, Button, Grid, Paper, TextField } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierType,
  ContainerDamageResponseDto,
  OrderResponseDto,
  UnitType,
} from '@planning/app/api'
import { IContainerDamage } from '@planning/components'
import { ControlledContainerSeals } from '@planning/components/ControlledContainerSeals'
import { useUnitTypeLabel } from '@planning/components/UnitType'
import { ControlledDamageRecordBox } from '@planning/components/damage-report/ControlledDamageRecordBox'
import { DangerousGoodAutoComplete } from '@planning/components/dangerous-goods/DangerousGoodsAutoComplete'
import { DamageReportBox } from '@planning/pages/TallymanV2/Components/DamageReportBox'
import { NavigationStackBackButton } from '@planning/pages/TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { validateIsoCode } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import {
  ContainerMobile,
  CustomInputAdornment,
  Header,
  StepperInfo,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'

interface IProps {
  order: OrderResponseDto
  damages?: IContainerDamage[]
  submitButtonText: string
  displayDamageReport?: boolean
  onSubmit: (data: IInspectContainerFormData) => Promise<void> | void
}

export interface IInspectContainerFormData extends OrderResponseDto {
  carrierType: CarrierType
  damages: ContainerDamageResponseDto[]
  damagesReported?: IContainerDamage[]
  hasDamage?: boolean
  isEdited?: boolean
}

export const InspectContainer: FC<IProps> = observer(
  ({ order, damages, submitButtonText, displayDamageReport, onSubmit }) => {
    const { t } = useTranslate()
    const { containerItemStore, appViewStore } = usePlanningStore()
    const theme = useMinimalsTheme()
    const hasNewDamageRecording = useBooleanFlagValue('damage-recording', false)

    useEffect(() => {
      if (order.containerId) containerItemStore.fetchById(order.containerId)
    }, [containerItemStore, order.containerId])

    const containerItem = computed(() => containerItemStore.elements[order.containerId ?? 0]).get()

    // TODO: Extract form as component without header
    const {
      register,
      control,
      handleSubmit,
      getValues,
      setValue,
      watch,
      formState: { errors },
    } = useForm({
      defaultValues: {
        id: order?.id,
        referenceNumber: order?.referenceNumber,
        direction: order?.direction,
        carrierType: order.carrierVisitType,
        containerId: order.containerId,
        containerNumber: order?.containerNumber,
        containerIsoCode: order?.containerIsoCode,
        containerTare: order?.containerTare,
        containerMaxGross: order?.containerMaxGross,
        isEmpty: order?.isEmpty,
        imoClasses: order?.imoClasses,
        holds: order?.holds,
        damages: damages ?? containerItem?.data.damages ?? [],
        hasDamage: !!(damages?.length ?? containerItem?.activeDamages.length),
        damagesReported: damages ?? [],
        seals: order?.seals ?? [],
        hasSeals: order?.hasSeals ?? false,
        isJobFinished: order?.isJobFinished,
      } as IInspectContainerFormData,
    })

    useEffect(() => {
      if (containerItem) {
        setValue('hasDamage', !!(damages?.length ?? containerItem?.data.damages.length))
      }
    }, [containerItem, damages?.length, setValue])

    const [defaultValuesData] = useState(getValues())
    const [isDangerous, setIsDangerous] = useState<boolean>(!!getValues('imoClasses')?.length)

    const pageTitle = `Inspect ${useUnitTypeLabel(containerItem && containerItem?.data ? containerItem?.data?.unitType : UnitType.Container)}`
    const unitTypeIsContainer = containerItem?.data.unitType === UnitType.Container

    const onFormSubmit = async (data: IInspectContainerFormData) => {
      try {
        data.isEdited = JSON.stringify(defaultValuesData) !== JSON.stringify(data)

        onSubmit(data)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const onEditDamageReport = (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ): void => {
      setValue('damages', [
        ...getValues('damages').filter(d => d.description !== initialReport.description),
        editedReport as ContainerDamageResponseDto,
      ])
    }

    const containerIsoCodeErrorText = (errors: FieldErrors) => {
      const isoCodeError = errors.containerIsoCode
      if (isoCodeError) {
        const errorType = isoCodeError.type
        if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
        if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

        return isoCodeError.message?.toString()
      }
    }

    const getStepText = () => {
      return watch('hasDamage') ? t('1of2', '1 of 2') : t('1of1', '1 of 1')
    }

    const getButtonText = () => {
      return watch('hasDamage') ? t('next', 'Next') : submitButtonText
    }

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          title={order?.containerNumber ?? ''}
          rightRenderOption={
            <Button
              variant='contained'
              onClick={handleSubmit(onFormSubmit)}
              sx={{ width: '15%', minWidth: '110px' }}
              data-cy='gate-in-inspect-container-submit-btn'
            >
              {getButtonText()}
            </Button>
          }
        />

        <StepperInfo title={pageTitle} steps={getStepText()} />

        {order && (
          <form>
            <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
              {unitTypeIsContainer && (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={`${t('iso', 'ISO')}*`}
                    variant='outlined'
                    {...register('containerIsoCode', {
                      required: true,
                      validate: v => !!v && validateIsoCode(v),
                    })}
                    name='containerIsoCode'
                    sx={{ marginTop: '1rem' }}
                    error={!!errors.containerIsoCode}
                    helperText={containerIsoCodeErrorText(errors)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`isEmpty`}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      label={`${t('state', 'State')}*`}
                      variant='outlined'
                      value={value ? t('empty', 'Empty') : t('full', 'Full')}
                      name='isEmpty'
                      sx={{ marginTop: '1rem' }}
                      disabled
                    />
                  )}
                />
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={`${t('maxGrossWeight', 'Max. gross weight')}`}
                    variant='outlined'
                    type='number'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register('containerMaxGross', { required: false, valueAsNumber: true })}
                    name='containerMaxGross'
                    sx={{ marginTop: '1rem' }}
                    data-cy='gate-in-inspect-container-max-gross-field'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={`${t('tareWeight', 'Tare weight')}`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position='end' color='secondary'>
                        kg
                      </CustomInputAdornment>
                    ),
                  }}
                  {...register('containerTare', { required: false, valueAsNumber: true })}
                  name='containerTare'
                  sx={{ marginTop: '1rem' }}
                  data-cy='gate-in-inspect-container-tare-weight-field'
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ marginTop: '1rem !important' }}>
                  <Paper variant='outlined' sx={{ p: '1rem' }}>
                    <Controller
                      control={control}
                      name={`imoClasses`}
                      rules={{ required: isDangerous }}
                      render={({ field: { onChange } }) => (
                        <DangerousGoodAutoComplete
                          assignedCodes={getValues(`imoClasses`) ?? []}
                          enableSelect={isDangerous}
                          onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
                          onDangerousGoodChange={newValues => {
                            onChange(newValues)
                          }}
                          error={!!errors.imoClasses}
                          helperText={
                            errors.imoClasses ? t('fieldIsRequired', 'Field is required.') : ''
                          }
                        />
                      )}
                    />
                  </Paper>
                </Box>
              </Grid>
              {displayDamageReport && containerItem && (
                <Grid item xs={12}>
                  <Box sx={{ marginTop: '1rem !important' }}>
                    {hasNewDamageRecording ? (
                      <ControlledDamageRecordBox control={control} name='hasDamage' />
                    ) : (
                      <Controller
                        control={control}
                        name={`damages`}
                        render={({ field: { onChange } }) => (
                          <DamageReportBox
                            containerId={0}
                            damages={getValues('damages')}
                            onCreateDamageReport={(_, data) =>
                              onChange([
                                ...getValues('damages'),
                                {
                                  ...(data as ContainerDamageResponseDto),
                                  created: new Date().toISOString(),
                                },
                              ])
                            }
                            onEditDamageReport={onEditDamageReport}
                          />
                        )}
                      />
                    )}
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                <ControlledContainerSeals
                  name='seals'
                  control={control}
                  sealsName='seals'
                  hasSealsName='hasSeals'
                />
              </Grid>
            </ContainerMobile>
          </form>
        )}
      </>
    )
  },
)
