import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { IssueType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'

interface Props {
  type: IssueType
}

const doNotShowIssueTypeChip = new Set<IssueType>([
  IssueType.CustomsPending,
  IssueType.CustomsRefused,
  IssueType.CustomsInspectionRequired,
])

export const IssueTypeChip = ({ type }: Props) => {
  const { t } = useTranslate()

  // As per discussed with Mark, let's hide this chip if the issue type relaters to Customs for now
  if (doNotShowIssueTypeChip.has(type)) {
    return <></>
  }

  return (
    <Box
      sx={{
        padding: '4px 8px',
        borderRadius: '4px',
        display: 'flex',
        height: '32px',
      }}
    >
      <Typography fontSize='12px' alignSelf='center' alignItems='center' fontWeight='bold'>
        {t(type)}
      </Typography>
    </Box>
  )
}
