import { TerminalType, UnLocationCodeDto } from '@admin/app/api'

export interface TenantFormProfile {
  id?: number
  name: string
  timezone?: string
  culture?: string
  address?: string | null
  isInlandJobCreationFlow?: boolean
  hasAutoGeneratedCraneSplit?: boolean
  skipCraneSplit?: boolean
  skipVesselTally?: boolean
  skipRailTally?: boolean
  skipRailTallyLoadPreparation?: boolean
  skipGateOut?: boolean
  skipVesselVisitReadyForOperation?: boolean
  skipReleaseOrder?: boolean
  skipYardPlanning?: boolean
  skipBerthTimestamp?: boolean
  bayRowUniqueIdentificationMode?: boolean
  showUnifiedOperatorPage?: boolean
  hasGeneralCargo?: boolean
  hasDamageCatalogue?: boolean
  operatorViewConfigs?: TerminalType
  unloCodes?: UnLocationCodeDto[]
  reeferContainerMaxUnpluggedTime?: number | null
  allowGateInWhenUnitNotOnTerminal?: boolean
  allowDirectDelivery?: boolean
}

export const defaultValues: TenantFormProfile = {
  name: '',
  timezone: '',
  culture: '',
  address: '',
  isInlandJobCreationFlow: false,
  hasAutoGeneratedCraneSplit: false,
  skipCraneSplit: false,
  skipVesselTally: false,
  skipRailTally: false,
  skipRailTallyLoadPreparation: false,
  skipGateOut: false,
  skipVesselVisitReadyForOperation: false,
  skipReleaseOrder: false,
  skipYardPlanning: false,
  skipBerthTimestamp: false,
  bayRowUniqueIdentificationMode: false,
  showUnifiedOperatorPage: false,
  hasGeneralCargo: false,
  hasDamageCatalogue: false,
  operatorViewConfigs: TerminalType.Hig,
  unloCodes: [],
  reeferContainerMaxUnpluggedTime: undefined,
  allowGateInWhenUnitNotOnTerminal: false,
  allowDirectDelivery: false,
}
