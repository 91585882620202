import { CoolingOrderDto, WorkInstructionJobDto } from '@operations/app/api'
import { CarrierVisitWorkInstructionUpsertedPayload } from '@operations/app/api/signalRDtos/carrierVisitWorkInstructionUpsertedPayload'
import { EquipmentLastPositionOnYardPayload } from '@operations/app/api/signalRDtos/EquipmentLastPositionOnYardPayload'
import { JobUpsertedUpdatedEventPayload } from '@operations/app/api/signalRDtos/JobUpsertedEventPayload'
import { WagonNumberDto } from '@operations/app/api/signalRDtos/wagonNumberDto'
import { WorkInstructionDeletedEventPayload } from '@operations/app/api/signalRDtos/workInstructionDeletedEventPayload'
import { IEvent } from '@operations/messages/messageBus'
import { AppStore } from '@tom-ui/utils'
import { EquipmentOperatorNotificationUIStore } from '../equipment-operator-notification.ui-store'
import { EquipmentOperatorWorkInstructionsUIStore } from '../equipment-operator-work-instructions.ui-store'
import { JobSignalRStrategy } from './JobSirgnalStrategy.interface'

export class WorkInstructionHandler implements JobSignalRStrategy {
  constructor(
    private appStore: AppStore,
    private equipmentOperatorNotificationUIStore: EquipmentOperatorNotificationUIStore,
    private equipmentOperatorWorkInstructionsUIStore: EquipmentOperatorWorkInstructionsUIStore,
  ) {}

  refreshVesselVisit = async () => {
    const currentWorkInstructionIdList =
      this.equipmentOperatorWorkInstructionsUIStore.jobsWithWIIds.map(x => x.workInstructionId)

    await this.appStore.triggerReloadBySignalR(
      this.equipmentOperatorWorkInstructionsUIStore.loadJobs,
      '/general-cargo-operator',
    )

    const newJobsWithWIId = this.equipmentOperatorWorkInstructionsUIStore.jobsWithWIIds.filter(
      x => !currentWorkInstructionIdList.includes(x.workInstructionId),
    )

    this.addNotification(newJobsWithWIId)

    const deletedJobWorkInstructionIds = currentWorkInstructionIdList.filter(
      x =>
        !this.equipmentOperatorWorkInstructionsUIStore.jobsWithWIIds
          .map(j => j.workInstructionId)
          .includes(x),
    )

    if (deletedJobWorkInstructionIds.length) {
      this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId(
        deletedJobWorkInstructionIds,
      )
    }
  }

  deleteWorkInstructions = (res: IEvent<WorkInstructionDeletedEventPayload>) => {
    const workInstructionIds = res.payload?.ids

    if (workInstructionIds?.length) {
      this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId(
        workInstructionIds,
      )
      this.equipmentOperatorWorkInstructionsUIStore.deleteStoreItems(workInstructionIds)
    }
  }

  finishWorkInstruction = (res: IEvent<number>) => {
    if (res.payload) {
      this.equipmentOperatorWorkInstructionsUIStore.deleteStoreItems([res.payload])
      this.equipmentOperatorNotificationUIStore.deleteJobsFromNotificationByWorkInstructionId([
        res.payload,
      ])
    }
  }

  upsertWorkInstructionJobs = (res: IEvent<WorkInstructionJobDto[]>) => {
    if (!res.payload || !res.payload.length) return

    const currentJobWorkInstructionIdList =
      this.equipmentOperatorWorkInstructionsUIStore.jobsWithWIIds.map(x => x.workInstructionId)

    const newJobs = res.payload.filter(
      x => !currentJobWorkInstructionIdList.includes(x.workInstructionId),
    )

    res.payload.forEach(wi =>
      this.equipmentOperatorWorkInstructionsUIStore.updateStoreItem(wi, wi.workInstructionId),
    )

    this.addNotification(newJobs)
  }

  refreshJobs = async () => {
    return
  }

  upsertJob = (_: IEvent<JobUpsertedUpdatedEventPayload>) => {
    return
  }

  updateWagonWeight = async (_: IEvent<WagonNumberDto>) => {
    return
  }

  updateEquipmentPositionOnYard = (res: IEvent<EquipmentLastPositionOnYardPayload>) => {
    return
  }

  updateContainerPlugInfo = (res: IEvent<CoolingOrderDto>) => {
    return
  }

  updateCarrierVisitWorkInstruction = (
    _res: IEvent<CarrierVisitWorkInstructionUpsertedPayload>,
  ) => {
    return
  }

  private addNotification(newJobs: WorkInstructionJobDto[]) {
    if (newJobs.length) {
      const workInstruction = newJobs.length === 1 ? newJobs[0] : undefined
      this.equipmentOperatorNotificationUIStore.addNotification({
        jobWorkInstructionIds: newJobs.map(x => x.workInstructionId),
        commodity: workInstruction?.cargoUnit.commodity.name,
        operationType: workInstruction?.operationType,
        destination: workInstruction?.destination,
        serviceType: workInstruction?.service?.type,
        carrierVisit: workInstruction?.carrierVisit?.carrierName,
        carrierType: workInstruction?.carrierVisit?.type,
        hasMultipleJobs: newJobs.length > 1,
      })
    }
  }
}
