import { Stack } from '@mui/material'
import { CustomsStatus } from '@planning/app/api'
import { AddIconButton } from '@planning/components/atoms/AddIconButton'
import { DeleteIconButton } from '@planning/components/atoms/DeleteIconButton'
import { NonPrimaryButton } from '@planning/components/atoms/NonPrimaryButton'
import { CustomsItem } from '@planning/components/molecules/CustomsItem'
import { MultiselectActionBar } from '@planning/components/molecules/MultiselectActionBar'
import { SectionHeader } from '@planning/components/molecules/SectionHeader'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CustomsViewStore } from '../../stores/CustomsViewStore'
import { EmptyCustoms } from '../Molecules/EmptyCustoms'

type Props = {
  store: CustomsViewStore
}

export const Customs = observer(({ store }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const {
    items,
    addCustoms,
    updateCustoms,
    deleteCustoms,
    setSelectAll,
    setSelectItem,
    updateSelectedCustoms,
    deleteSelectedCustoms,
    selectAllState,
    selectItemStates,
    errors,
  } = store

  const actions = [
    <NonPrimaryButton
      key={CustomsStatus.Pending}
      text={t('pending', 'Pending')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Pending)}
      size='small'
    />,
    <NonPrimaryButton
      key={CustomsStatus.InspectionRequired}
      text={t('inspectionRequired', 'Inspection required')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Refused)}
      size='small'
    />,
    <NonPrimaryButton
      key={CustomsStatus.Cleared}
      text={t('cleared', 'Cleared')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Cleared)}
      size='small'
    />,
    <NonPrimaryButton
      key={CustomsStatus.Refused}
      text={t('refused', 'Refused')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Refused)}
      size='small'
    />,
  ]

  const secondaryActions = [
    <DeleteIconButton key={'deleted'} onClick={() => deleteSelectedCustoms()} />,
  ]

  return (
    <Stack gap={theme.customSpacing.xs}>
      <SectionHeader
        label={t('customsReferenceAndStatus', 'Customs references & status’')}
        actions={[
          <AddIconButton
            key={'add'}
            onClick={() => addCustoms({ reference: '', status: CustomsStatus.Pending })}
          />,
        ]}
      />
      {items.length ? (
        <MultiselectActionBar
          state={selectAllState}
          handleOnStateChange={(checked: boolean) => setSelectAll(checked)}
          label={t('setTo', 'Set to')}
          actions={actions}
          secondaryActions={secondaryActions}
        />
      ) : (
        <EmptyCustoms />
      )}
      {items.map((customs, index) => (
        <CustomsItem
          key={index}
          state={selectItemStates[index]}
          onChangeState={(checked: boolean) => setSelectItem(index, checked)}
          customs={customs}
          onChangeCustoms={customs => updateCustoms(index, customs)}
          onDelete={() => deleteCustoms(index)}
          error={errors[index]}
        />
      ))}
    </Stack>
  )
})
