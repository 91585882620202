import {
  AllocationsApiDangerousGoodsValidationRequest,
  CarrierType,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  ContainerTurnoverDto,
  ContainerTurnoversFilterDto,
  CreateAllocationRuleDto,
  ErrorCodes,
  GlobalAllocationRuleDto,
  YardPositionDto,
} from '@storage/app/api'
import {
  getApplicationDomainExceptionPayload,
  isApplicationDomainException,
} from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { WeightClassContainerUIStore } from '@storage/features/weight-classes/stores/weight-class-container-ui-store'
import { ContainerTurnoversFilterFormProfile } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { mapFormValuesToFilterDto } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form/container-turnovers-filter-form.mapper'
import { ManualPlanningDialogStore } from '@storage/pages/container-turnovers/stores/manual-planning-dialog.store'
import { GlobalAllocationRuleStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.store'
import { mapCarrierVisitDiscriminatorToCarrierType } from '@storage/pages/yard-planning-dashboard-details/mappers'
import { getFilteredTurnovers } from '@storage/pages/yard-planning-dashboard-details/utils/turnovers-filtering.util'
import { ContainerPlanningService } from '@storage/services/container-planning.service'
import { AlertStore } from '@storage/stores/alert.store'
import { BaseAllocationRulesUIStore } from '@storage/stores/base-allocation-rules.ui-store'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { UnallocatedTurnoversStore } from '@storage/stores/unallocated-turnovers.store'
import {
  getInsufficientPlanningSpaceMsg,
  getPlanningHasReservedMsg,
} from '@storage/utils/translation'
import { AxiosError } from 'axios'
import { action, makeObservable, observable } from 'mobx'
import {
  mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto,
  mapYardPositionDtoToYardPositionDescriptor,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import {
  mapAllocationRuleDtoToCreateAllocationRuleDto,
  mapGlobalAllocationRuleDtoToCarrierVisitAllocationRuleDto,
} from '../mappers'
import { CarrierVisitAllocationRulesV2Store } from './carrier-visit-allocation-rules-v2.store'

export class CarrierVisitAllocationRulesV2UIStore extends BaseAllocationRulesUIStore<CarrierVisitAllocationRuleDto> {
  searchQuery = ''
  isAllocationRequestLoading = false
  isDangerousGoodsDialogOpen = false
  dialogText = ''
  persistAllChangesIsLoading = false
  showDestinationFormInTemplate = false
  carrierVisitContext?: {
    carrierVisitDirection: CarrierVisitDirection
    carrierType: CarrierType
  }

  constructor(
    private readonly _carrierVisitAllocationRulesStore: CarrierVisitAllocationRulesV2Store,
    private readonly _unallocatedTurnoversStore: UnallocatedTurnoversStore,
    private readonly _globalAllocationRuleStore: GlobalAllocationRuleStore,
    private readonly _alertStore: AlertStore,
    private readonly _snackbarStore: SnackbarStore,
    private readonly _containerPlanningService: ContainerPlanningService,
    private readonly _weightClassContainerUIStore: WeightClassContainerUIStore,
    public readonly containerDialogUtilStore: DialogUtilStore,
    public readonly listItemDialogUtilStore: DialogUtilStore,
  ) {
    super()
    makeObservable(this, {
      isAllocationRequestLoading: observable,
      toggleAllocationRequestState: action,

      isDangerousGoodsDialogOpen: observable,
      dialogText: observable,
      toggleDangerousGoodsDialog: action,
      setDialogText: action,

      persistAllChangesIsLoading: observable,
      setPersistAllChangesLoadingState: action,

      showDestinationFormInTemplate: observable,
      setShowDestinationFormInTemplate: action,

      carrierVisitContext: observable,
      setCarrierVisitContext: action,
    })
  }

  getAllocationRuleById(id: string) {
    return this._carrierVisitAllocationRulesStore.getById(id)
  }

  setShowDestinationFormInTemplate(show: boolean) {
    this.showDestinationFormInTemplate = show
  }

  setCarrierVisitContext(direction: CarrierVisitDirection, discriminator: string) {
    this.carrierVisitContext = {
      carrierVisitDirection: direction,
      carrierType: mapCarrierVisitDiscriminatorToCarrierType(discriminator),
    }
  }

  async loadAllocationRules(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    await this._carrierVisitAllocationRulesStore.loadAll(carrierVisitId, carrierVisitDirection)
  }

  async loadGlobalAllocationRules(
    carrierVisitDirection: CarrierVisitDirection,
    carrierType: CarrierType,
  ) {
    await this._globalAllocationRuleStore.loadAll(carrierVisitDirection, carrierType)
  }

  // Has all rules (related global & carrier visit rules)
  get allocationRules(): CarrierVisitAllocationRuleDto[] {
    if (!this.carrierVisitGlobalAllocationRules.length) {
      return this.carrierVisitAllocationRules
    }

    return [
      ...this.carrierVisitAllocationRules,
      ...this.carrierVisitGlobalAllocationRules.map(rule =>
        mapGlobalAllocationRuleDtoToCarrierVisitAllocationRuleDto(rule),
      ),
    ]
  }

  get carrierVisitAllocationRules(): CarrierVisitAllocationRuleDto[] {
    return this._carrierVisitAllocationRulesStore.entries
  }

  get carrierVisitGlobalAllocationRules(): GlobalAllocationRuleDto[] {
    return this._globalAllocationRuleStore.entries
  }

  get isCarrierVisitAllocationRulesRequestLoading() {
    return this._carrierVisitAllocationRulesStore.isLoading
  }

  get isCarrierVisitGlobalAllocationRulesRequestLoading() {
    return this._globalAllocationRuleStore.isLoading
  }

  getAllocationSummary(allocationRuleId: string) {
    return this.allocationRuleSummaries.get(allocationRuleId)
  }

  get selectedAllocationRulePosition(): string {
    if (!this.selectedAllocationRule) {
      return ''
    }
    return mapYardPositionDtoToYardPositionDescriptor(
      this.selectedAllocationRule.destinationAndStackingStrategies,
    )[0]
  }

  async reorderAllocationRules(startIndex: number, endIndex: number) {
    this._carrierVisitAllocationRulesStore.reorderAllocationRules(startIndex, endIndex)
    let turnovers = this._unallocatedTurnoversStore.containerTurnovers

    this.allocationRules.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })
  }

  setPersistAllChangesLoadingState(isLoading: boolean) {
    this.persistAllChangesIsLoading = isLoading
  }

  persistAllChanges(carrierVisitId: number, direction: CarrierVisitDirection) {
    this.setPersistAllChangesLoadingState(true)

    const allocationRules: CreateAllocationRuleDto[] = this.carrierVisitAllocationRules.map(
      (rule, index) => {
        const turnovers = this.allocationRuleSummaries.get(rule.id)
        return mapAllocationRuleDtoToCreateAllocationRuleDto(rule, index + 1, turnovers ?? [])
      },
    )

    return this._carrierVisitAllocationRulesStore.saveChanges(
      carrierVisitId,
      direction,
      allocationRules,
    )
  }

  toggleAllocationRequestState() {
    this.isAllocationRequestLoading = !this.isAllocationRequestLoading
  }

  toggleDangerousGoodsDialog() {
    this.isDangerousGoodsDialogOpen = !this.isDangerousGoodsDialogOpen
  }

  setDialogText(text: string) {
    this.dialogText = text
  }

  handleDestinationsHighlight() {
    super.handleDestinationsHighlight(this.allocationRules)
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }

  get alerts() {
    return this._alertStore.getAlerts()
  }

  get panelActionButtonLabel() {
    const hasAllocationSpaceAlert = this._alertStore.doesAlertExist(
      ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
    )
    return hasAllocationSpaceAlert
      ? tolgee.t('allocateAnyway', 'Allocate Anyway')
      : tolgee.t('allocate', 'Allocate')
  }

  toggleDeletionConfirmationDialog = (dialogEntityId: string) => {
    this.listItemDialogUtilStore.toggleDialog('Delete', dialogEntityId)
  }

  preAllocationValidation(filter: ContainerTurnoversFilterDto) {
    // Clear alerts
    this._alertStore.clearAlerts()

    this._containerPlanningService
      .prePlanningValidation({
        filter,
      })
      .catch((error: AxiosError) => this.handleDomainException(error))
  }

  clearAlerts() {
    this._alertStore.clearAlerts()
  }

  async createAllocation(filter: ContainerTurnoversFilterDto) {
    if (!this.selectedAllocationRule) {
      return
    }

    const forceCreation = this._alertStore.doesAlertExist(
      ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
    )

    await this._containerPlanningService.createAllocation({
      filter,
      yardPosition: this.selectedAllocationRule.destinationAndStackingStrategies[0].destination,
      forceCreation,
    })
  }

  async validateDangerousGoodsPlanning(
    filter: ContainerTurnoversFilterFormProfile,
    numberOfCTsMatchingFilter: number,
    onSuccess: () => void,
  ) {
    try {
      await this.validateAllocationRequest(
        mapFormValuesToFilterDto(filter, false),
        this.selectedAllocationRule?.destinationAndStackingStrategies[0].destination,
      )
      this.allocationRequest(
        mapFormValuesToFilterDto(filter, false),
        numberOfCTsMatchingFilter,
        () => onSuccess(),
      )
    } catch (error: any) {
      if (
        isApplicationDomainException(
          error,
          ErrorCodes.PreplanningMixedDangerousareaNonDangerouscontainers,
        )
      ) {
        this.toggleDangerousGoodsDialog()
        this.setDialogText(
          tolgee.t(
            'dangerousLocationNonDangerousSelection',
            'You are trying to allocate non-dangerous goods to a dangerous goods location',
          ),
        )
      }
      if (
        isApplicationDomainException(
          error,
          ErrorCodes.PreplanningMixedNonDangerousareaDangerouscontainers,
        )
      ) {
        this.toggleDangerousGoodsDialog()
        this.setDialogText(
          tolgee.t(
            'nonDangerousLocationDangerousSelection',
            'You are trying to allocate dangerous goods to a non-dangerous goods location',
          ),
        )
      }
    }
  }

  async validateAllocationRequest(
    filter: ContainerTurnoversFilterDto,
    yardPosition?: YardPositionDto,
  ) {
    if (!yardPosition) {
      return
    }
    const request: AllocationsApiDangerousGoodsValidationRequest = {
      allocationsDangerousGoodsValidationRequest: {
        filter,
        yardPosition,
      },
    }
    await this._containerPlanningService.dangerousGoodsPrePlanningValidation(request)
  }

  async allocationRequest(
    filter: ContainerTurnoversFilterDto,
    numberOfCTsMatchingFilter: number,
    onSuccess: () => void,
  ) {
    this.toggleAllocationRequestState()
    const req = this.createAllocation(filter)
    //TODO: refactor repetitive requests with manualPlanningRequest
    req
      .then(() => {
        this._snackbarStore.showMessage(
          tolgee.t(
            'containersPlanned',
            '{n} containers has been successfully planned to {destination}',
            {
              n: numberOfCTsMatchingFilter,
              destination: this.selectedAllocationRulePosition,
            },
          ),
          'success',
        )
        onSuccess()
      })
      .catch(error => {
        // Check for allocation space conflict (not enough allocation space)
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          const payload = getApplicationDomainExceptionPayload(error)

          this._alertStore.showAlert(
            ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
            getInsufficientPlanningSpaceMsg(payload.locationsAvailable),
          )
        } else {
          // Generic error
          this._snackbarStore.showMessage(
            tolgee.t(
              'cantPlanAtThisPosition',
              `Something went wrong. We can't plan at this position`,
            ),
            'error',
          )
        }
      })
      .finally(() => this.toggleAllocationRequestState())
  }

  addAllocationRule(formValues: AllocationRuleTemplateFormProfile) {
    if (!this.carrierVisitContext) return

    const carrierVisitAllocationRule =
      mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto(
        {
          ...formValues,
          facets: { ...formValues.facets, carrierType: this.carrierVisitContext.carrierType },
        },
        this.carrierVisitContext.carrierVisitDirection,
      )

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    this.filterTurnoversByAllocationRule(
      carrierVisitAllocationRule,
      this._unallocatedTurnoversStore.tempContainerTurnovers,
    )

    this.setSelectedAllocationRule(carrierVisitAllocationRule)

    this.hideAllocationRuleForm()
  }

  updateAllocationRule(formValues: AllocationRuleTemplateFormProfile) {
    if (!this.carrierVisitContext) return

    const carrierVisitAllocationRule =
      mapAllocationRuleTemplateFormProfileToCarrierVisitAllocationRuleDto(
        {
          ...formValues,
          facets: { ...formValues.facets, carrierType: this.carrierVisitContext.carrierType },
        },
        this.carrierVisitContext.carrierVisitDirection,
      )

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    const allocatedTurnovers = this._unallocatedTurnoversStore.containerTurnovers.filter(
      x => x.isAllocated,
    )
    this._unallocatedTurnoversStore.resetTempTurnovers(allocatedTurnovers)

    let turnovers = this._unallocatedTurnoversStore.tempContainerTurnovers
    this.allocationRules.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    this.setSelectedAllocationRule(carrierVisitAllocationRule)

    this.hideAllocationRuleForm()
  }

  deleteAllocationRule() {
    this._carrierVisitAllocationRulesStore.delete(this.listItemDialogUtilStore.dialogEntityId!)
    const allocationRuleTurnovers = this.getAllocationSummary(
      this.listItemDialogUtilStore.dialogEntityId!,
    )

    if (allocationRuleTurnovers) {
      this._unallocatedTurnoversStore.resetTempTurnovers(allocationRuleTurnovers)
    }
    let turnovers = this._unallocatedTurnoversStore.tempContainerTurnovers

    this.allocationRules.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    this.setSelectedAllocationRule()

    this.listItemDialogUtilStore.toggleDialog()
  }

  filterTurnoversByAllocationRule(
    allocationRule: CarrierVisitAllocationRuleDto,
    containerTurnovers: ContainerTurnoverDto[],
  ) {
    const filteredTurnovers = getFilteredTurnovers(
      allocationRule,
      containerTurnovers,
      this._weightClassContainerUIStore.weightClasses,
    )

    const remainingTurnovers = containerTurnovers.filter(
      turnover => !filteredTurnovers.includes(turnover),
    )
    this.setAllocationRuleSummary(allocationRule.id, filteredTurnovers)
    this._unallocatedTurnoversStore.setTempTurnovers(remainingTurnovers)
    return remainingTurnovers
  }

  private handleDomainException(error: AxiosError) {
    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedContainerTurnoverSizes)) {
      return
    }

    if (isApplicationDomainException(error, ErrorCodes.PreplanningHasReserved)) {
      const payload = getApplicationDomainExceptionPayload(error, ErrorCodes.PreplanningHasReserved)
      this._alertStore.showAlert(
        ErrorCodes.PreplanningHasReserved,
        getPlanningHasReservedMsg(payload.reservedCount),
      )
    }
    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedDangerousAndNonDangerous)) {
      this._alertStore.showAlert(
        ErrorCodes.PreplanningMixedDangerousAndNonDangerous,
        tolgee.t(
          'planningContainerOfDifferentHandling',
          `Pay attention! you're planning dangerous & non dangerous containers`,
        ),
      )
    }

    if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedFullAndEmpties)) {
      this._alertStore.showAlert(
        ErrorCodes.PreplanningMixedFullAndEmpties,
        tolgee.t(
          'preplanningMixedFullAndEmpties',
          `Pay attention! you're planning full & empty containers`,
        ),
      )
    }
  }
}
