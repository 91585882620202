import { useAuth0 } from '@auth0/auth0-react'
import LogoutIcon from '@mui/icons-material/Logout'
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'

interface Props {
  showSelectEquipment?: boolean
  showCorrectPosition?: boolean
}
export const HeaderAvatar = ({ showSelectEquipment, showCorrectPosition }: Props) => {
  const { logout, user } = useAuth0()
  const { t } = useTranslate()
  const navigate = useNavigate()
  const { page } = useParams()
  const { appStore } = useOperationsStore()

  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const avatarInitials = user?.name
    ?.split(' ')
    .map(word => word.charAt(0))
    .join('')

  const handleCorrectPositionClick = () => {
    const url = '/storage/operator-container-turnovers-list'
    navigate(url, { state: { key: window.location.href } })
  }

  return (
    <>
      <Avatar
        id='logout-menu'
        sx={{ bgcolor: '#C4CDD5', cursor: 'pointer', fontSize: '0.9rem' }}
        onClick={handleClick}
      >
        {avatarInitials}
      </Avatar>

      <Menu
        id='logout-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: 1 }}
      >
        {showSelectEquipment && (
          <MenuItem
            onClick={async () => {
              await appStore.removeAllAlerts()
              navigate(`/Operator/${page}`)
            }}
          >
            {t('selectEquipment', 'Select equipment')}
          </MenuItem>
        )}

        {showCorrectPosition && (
          <MenuItem onClick={handleCorrectPositionClick}>
            {t('correctPosition', 'Correct position')}
          </MenuItem>
        )}

        <MenuItem
          onClick={async () => {
            logout({ logoutParams: { returnTo: window.location.origin } })
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('logout', 'Logout')}
        </MenuItem>
      </Menu>
    </>
  )
}
