import { Button, CardContent, CardHeader, Stack } from '@mui/material'
import Card from '@mui/material/Card'
import {
  EquipmentType,
  JobDto,
  OperatorFields,
  WorkInstructionWarningReason,
} from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'
import { JobAlert } from './JobAlert'
import { JobContainerNumber } from './JobContainerNumber'
import { JobDescription } from './JobDescription'
import { JobJourney } from './JobJourney'

interface Props {
  job: JobDto
  isConfirm?: boolean
  currentEquipmentType?: EquipmentType
  onConfirm: (job: JobDto) => void
  highlightText?: string
  fieldsToShow?: OperatorFields
}

export const JobCard = ({
  job,
  isConfirm,
  currentEquipmentType,
  onConfirm,
  highlightText,
  fieldsToShow,
}: Props) => {
  const { t } = useTranslate()

  const handleConfirm = () => {
    onConfirm(job)
  }

  let destination = job.to.locationName
  if (currentEquipmentType !== EquipmentType.Tt && job.linkedOutboundWorkInstruction?.destination)
    destination = job.linkedOutboundWorkInstruction.destination.locationName

  return (
    <Card
      sx={{ width: '100%', height: '100%' }}
      data-cy={`job-card-${job.cargoUnit?.displayName ?? 'non-numeric'}`}
    >
      <CardHeader
        title={
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <JobContainerNumber job={job} highlightText={highlightText} />

            {job.isOptional && <ColoredLabel label={t('optional', 'Optional')} color='secondary' />}
          </Stack>
        }
        sx={{ paddingBottom: theme => theme.customSpacing.xs }}
      />

      <JobDescription job={job} fieldsToShow={fieldsToShow} />

      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem', height: '100%' }}>
        <JobAlert warningReason={job.warningReason} />

        <JobJourney
          origin={job.from.locationName}
          destination={destination}
          requireYardReservation={
            job.warningReason === WorkInstructionWarningReason.RequiresReservation
          }
        />

        <Stack gap={1} paddingY={theme => theme.customSpacing.xs}>
          <Button
            color='primary'
            variant='contained'
            size='large'
            onClick={handleConfirm}
            disabled={job.isPlanned}
            data-cy='confirm-container-job-btn'
          >
            {isConfirm ? t('confirmMove', 'Confirm move') : t('startMove', 'Start move')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
