import { Alert, Autocomplete, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore, workInstructionService } from '@tom-ui/operations'
import { containerPlanningService, YardPositionSelector } from '@tom-ui/storage'
import { YardPositionDto } from '@tom-ui/storage/app/api'
import { ConfirmationDialog, useMinimalsTheme } from '@tom-ui/ui'
import { ContainerMovementInfo, useUtilsStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import StackedAlert from 'modules/storage/src/components/StackedAlert'
import { useContainerTurnoversStores } from 'modules/storage/src/pages/container-turnovers/hooks/container-turnovers-stores.hook'
import { AppAlert } from 'modules/storage/src/stores/alert.store'
import { useMemo } from 'react'
import {
  ContainerMovementAlertType,
  ContainerMovementDialogViewStore,
  InternalMovementServiceCalls,
} from './container-movement-dialog.view-store'

export const ContainerMovementDialog = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { containerMovementDialogStore } = useUtilsStore()
  const { appStore } = useOperationsStore()

  const { containerTurnoversListStore } = useContainerTurnoversStores()

  const viewStore = useMemo(() => {
    const serviceCalls: InternalMovementServiceCalls = {
      storageCreateInternalMoveManual: containerPlanningService.createInternalMoveManual,
      operationsCreateInternalWorkInstruction: workInstructionService.createInternalWorkInstruction,
    }

    return new ContainerMovementDialogViewStore(containerMovementDialogStore, serviceCalls)
  }, [containerMovementDialogStore])

  const handleClose = () => {
    viewStore.reset()
    containerMovementDialogStore.closeDialog()
  }

  const handleSubmit = async () => {
    try {
      await viewStore.submit()
      await containerTurnoversListStore.reload(true)

      viewStore.reset()
      containerMovementDialogStore.closeDialog()
      appStore.setShowAlert('success', t('containerMoveCreated', 'Container movement created'))
    } catch {
      //
    }
  }

  const handleContainerChange = (containers: ContainerMovementInfo[]) => {
    containerMovementDialogStore.setSelectedContainerNumbers(containers.map(x => x.containerNumber))
  }

  const handleDestinationChange = (destination: YardPositionDto, isDangerousYardBlock: boolean) => {
    viewStore.setDestination(destination, isDangerousYardBlock)
  }

  const getContainerOptionLabel = (option: ContainerMovementInfo) => {
    if (viewStore.hasMixedSizes) {
      return `${option.containerNumber} (${option.size}ft)`
    } else {
      return option.containerNumber
    }
  }

  const getAlertMessages = () => {
    return viewStore.alerts.map((alert, index) => {
      let message

      switch (alert) {
        case ContainerMovementAlertType.DgSegregationRulesViolation:
          message = t(
            'segregationRulesViolationPlan',
            'Be aware, you action violates the current segreation rules.',
          )
          break
        case ContainerMovementAlertType.DgStackingRulesViolationMaxAllowedTier:
          message = t(
            'stackingValidationMaxStackTierPlan',
            "We can't plan at this position. There is a maximum tier stacking rule in place for this position.",
          )
          break
        case ContainerMovementAlertType.DgStackingRulesViolationStackingOnTop:
          message = t(
            'stackingValidationStackingOnTopPlan',
            "We can't plan at this position. There is a stacking rule that prohibits stacking on top",
          )
          break
        case ContainerMovementAlertType.MixedFullAndEmpties:
          message = t(
            'preplanningMixedFullAndEmpties',
            "Pay attention! you're planning full & empty containers",
          )
          break
        case ContainerMovementAlertType.MixedDangerousAndNonDangerous:
          message = t(
            'planningContainerOfDifferentHandling',
            "Pay attention! you're planning dangerous & non dangerous containers",
          )
          break
        case ContainerMovementAlertType.MixedDangerousareaNonDangerouscontainers:
          message = t(
            'dangerousLocationNonDangerousSelection',
            'You are trying to allocate non-dangerous goods to a dangerous goods location',
          )
          break
        case ContainerMovementAlertType.MixedNonDangerousareaDangerouscontainers:
          message = t(
            'nonDangerousLocationDangerousSelection',
            'You are trying to allocate dangerous goods to a non-dangerous goods location',
          )
          break
        case ContainerMovementAlertType.AllocationSpaceConflict:
          message = t('insufficientPlanningSpace', 'Insufficient planning space')
          break
        default:
          message = t(
            'unhandledContainerMovementAlertType',
            'Unhandled container movement alert type',
          )
          break
      }

      const appAlert: AppAlert = {
        key: index.toString(),
        message,
        severity: 'warning',
        date: new Date(),
      }

      return appAlert
    })
  }

  return (
    <ConfirmationDialog
      open={containerMovementDialogStore.isOpen}
      title={t('containerMovement', 'Container movement')}
      primaryActionText={t('move', 'Move')}
      closeLabel={t('cancel', 'Cancel')}
      primaryActionDisabled={viewStore.isMovementInvalid}
      maxWidth='md'
      onClose={handleClose}
      onConfirm={handleSubmit}
    >
      <Stack gap={theme.customSpacing.m}>
        {containerMovementDialogStore.containers.length > 1 && (
          <Typography>
            {t(
              'containerMovementAmountLabel',
              'You have {amount} containers to be moved. You can still change this amount before submit',
              {
                amount: containerMovementDialogStore.containers.length,
              },
            )}
          </Typography>
        )}

        <Autocomplete
          multiple
          options={containerMovementDialogStore.containers}
          value={containerMovementDialogStore.selectedContainers}
          renderInput={params => (
            <TextField {...params} label={t('containerNumbers', 'Container numbers')} />
          )}
          getOptionLabel={getContainerOptionLabel}
          isOptionEqualToValue={(option, value) => value.containerNumber === option.containerNumber}
          onChange={(e, value) => handleContainerChange(value)}
        />

        <YardPositionSelector
          containers={containerMovementDialogStore.selectedContainers}
          handleChange={handleDestinationChange}
        />

        {containerMovementDialogStore.hasSelectedMixedSizes && (
          <Alert severity='error'>
            {t(
              'movingContainersVaryingSizesNotPermitted',
              'Moving containers of varying sizes is not permitted. Please select only containers of the same size',
            )}
          </Alert>
        )}

        {!containerMovementDialogStore.hasSelectedMixedSizes && viewStore.alerts.length > 0 && (
          <StackedAlert alerts={getAlertMessages()} />
        )}
      </Stack>
    </ConfirmationDialog>
  )
})
