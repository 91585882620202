import { Box, Button, Divider, Menu, MenuItem, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection } from '@planning/app/api'
import { GateInGeneralCargoOrderListItem } from '@planning/pages/GateControl/Components/GateInGeneralCargoOrderListItem'
import { GateInOrderListItem } from '@planning/pages/GateControl/Components/GateInOrderListItem'
import { CreateGeneralCargoOrder } from '@planning/pages/Order/CreateGeneralCargoOrders'
import { CreateOrdersPage } from '@planning/pages/Order/CreateOrders'
import { GateInViewStore } from '@planning/stores/gateControl/GateInViewStore'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import { IconButton, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { IInspectContainerFormData } from './InspectContainer'
import { NNROrderListItem } from './NNROrderListItem'
import { OrderListItem } from './OrderListItem'

interface Props {
  orders: IInspectContainerFormData[]
  dropOffGeneralCargoOrders?: IInspectContainerFormData[]
  pickUpGeneralCargoOrders?: IInspectContainerFormData[]
  nnrOrders?: NonNumericOrderWithPickUpAmount[]
  type: 'PickUp' | 'DropOff'
  onAddOrder?: (order?: IInspectContainerFormData) => void
  onEditOrder?: (order: IInspectContainerFormData) => void
  onRemoveOrder?: (order: IInspectContainerFormData) => void
  onRemoveGeneralCargoOrder?: (order: IInspectContainerFormData) => void
  onRemoveNNROrder?: (order: NonNumericOrderWithPickUpAmount) => void
  onEditNnrOrderAmount?: (nnrOrder: NonNumericOrderWithPickUpAmount) => void
  useSearchAutoComplete?: boolean
  renderAutocomplete?: () => JSX.Element
  error: boolean
  isAllowedToCreateOrders?: boolean
  validateOutboundRequest?: ValidateOutboundOrderFunc
  prefilledUnitNumber?: string
}

const CreateOrderMenu: FC<{
  forcedDirection: CarrierVisitDirection
  gateInViewStore: GateInViewStore
  prefilledUnitNumber?: string
}> = ({ forcedDirection, gateInViewStore, prefilledUnitNumber }) => {
  const { dialogStore } = usePlanningStore()
  const { t } = useTranslate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        size='small'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
      >
        {t('createOrder', 'createOrder')}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() =>
            dialogStore.openDialog(
              <CreateOrdersPage
                isGateControl
                prefilledUnitNumber={prefilledUnitNumber}
                isTruckAppointment
                orderCreationOnly
                forcedDirection={forcedDirection}
                onSubmit={orderId => {
                  if (forcedDirection === CarrierVisitDirection.Inbound) {
                    gateInViewStore.upsertDropoffOrderById(orderId)
                  } else {
                    gateInViewStore.upsertPickUpOrderById(orderId)
                  }
                }}
              />,
            )
          }
        >
          {t('unit', 'Unit')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            dialogStore.openDialog(
              <CreateGeneralCargoOrder isTruckAppointment forcedDirection={forcedDirection} />,
            )
          }
        >
          {t('generalCargo', 'General Cargo')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export const OrderHandlingBox: FC<Props> = observer(
  ({
    orders,
    dropOffGeneralCargoOrders,
    pickUpGeneralCargoOrders,
    nnrOrders,
    type,
    onAddOrder,
    onEditOrder,
    onRemoveOrder,
    onEditNnrOrderAmount,
    onRemoveNNROrder,
    onRemoveGeneralCargoOrder,
    useSearchAutoComplete,
    renderAutocomplete,
    error,
    isAllowedToCreateOrders,
    validateOutboundRequest,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { gateInViewStore } = usePlanningStore()
    const pickUpSearchType = gateInViewStore.pickUpOrderSearchStore.searchType
    const dropOffSearchType = gateInViewStore.searchType

    const titleMap = {
      PickUp: t('pickUps', 'Pick ups'),
      DropOff: t('dropOffs', 'Drop offs'),
    }
    const emptyListLabelMap = {
      PickUp: t('exitsEmpty', 'Exits empty'),
      DropOff: t('entersEmpty', 'Enters empty'),
    }

    const [isAddingOrder, setIsAddingOrder] = useState<boolean>(false)

    const toggleShowAutocomplete = () => {
      setIsAddingOrder(!isAddingOrder)
    }

    const EmptyList = () => (
      <Typography
        color={!error ? theme.palette.grey[500] : theme.palette.error.main}
        textAlign={'center'}
      >
        {emptyListLabelMap[type]}
      </Typography>
    )

    const unitNumber =
      type === 'DropOff'
        ? gateInViewStore.dropOffOrderSearchStore.filter
        : gateInViewStore.pickUpOrderSearchStore.filter

    return (
      <Box
        sx={{
          border: '1px solid',
          borderColor: !error ? 'rgba(145, 158, 171, 0.32)' : theme.palette.error.main,
          borderRadius: theme.customRadius.medium,
          mb: '1rem !important',
        }}
      >
        <Box
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='subtitle2'>{titleMap[type]}</Typography>
          <Box display='flex' alignItems='center'>
            {useSearchAutoComplete && isAddingOrder && isAllowedToCreateOrders && (
              <CreateOrderMenu
                gateInViewStore={gateInViewStore}
                prefilledUnitNumber={unitNumber}
                forcedDirection={
                  type === 'DropOff'
                    ? CarrierVisitDirection.Inbound
                    : CarrierVisitDirection.Outbound
                }
              />
            )}

            {(useSearchAutoComplete || onAddOrder) && (
              <IconButton
                data-cy={`order-handling-add-${type}-order-btn`}
                onClick={() => (useSearchAutoComplete ? toggleShowAutocomplete() : onAddOrder?.())}
              >
                <PlusIcon
                  sx={{
                    transform: useSearchAutoComplete && isAddingOrder ? 'rotate(45deg)' : '0',
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Box>

        {useSearchAutoComplete && isAddingOrder && renderAutocomplete && (
          <Box
            sx={{ pr: theme.customSpacing.m, pl: theme.customSpacing.m, pb: theme.customSpacing.m }}
          >
            {renderAutocomplete()}
          </Box>
        )}

        <Divider sx={{ borderColor: !error ? '' : theme.palette.error.main }} />
        <Box
          sx={{
            padding: '1rem',
            bgcolor: !error ? theme.palette.grey[100] : theme.palette.error.lighter,
            borderBottomLeftRadius: theme.customRadius.medium,
            borderBottomRightRadius: theme.customRadius.medium,
          }}
        >
          {type === 'PickUp' &&
            useSearchAutoComplete &&
            pickUpSearchType === 'generalCargo' &&
            pickUpGeneralCargoOrders &&
            pickUpGeneralCargoOrders.length > 0 &&
            pickUpGeneralCargoOrders?.map(o => (
              <GateInGeneralCargoOrderListItem
                key={`gate-in-order-list-item-${o.id}`}
                order={o}
                onRemove={onRemoveGeneralCargoOrder}
                validateOutboundRequest={validateOutboundRequest}
              />
            ))}

          {type === 'DropOff' &&
            useSearchAutoComplete &&
            dropOffSearchType === 'generalCargo' &&
            dropOffGeneralCargoOrders &&
            dropOffGeneralCargoOrders.length > 0 &&
            dropOffGeneralCargoOrders?.map(o => (
              <GateInGeneralCargoOrderListItem
                key={`gate-in-order-list-item-${o.id}`}
                order={o}
                onRemove={onRemoveGeneralCargoOrder}
              />
            ))}

          {/* NNR Orders */}
          {type === 'PickUp' &&
            pickUpSearchType !== 'generalCargo' &&
            nnrOrders!.length > 0 &&
            nnrOrders?.map(nnr => (
              <NNROrderListItem
                key={`nnr-order-list-item-${nnr.id}`}
                nnrOrder={nnr}
                onRemove={onRemoveNNROrder}
                showThreeDotMenu={useSearchAutoComplete}
                onEditPickUpAmount={onEditNnrOrderAmount}
              />
            ))}

          {useSearchAutoComplete &&
            type === 'DropOff' &&
            dropOffSearchType !== 'generalCargo' &&
            orders!.length > 0 &&
            orders.map(o => (
              <GateInOrderListItem
                key={`gate-in-order-list-item-${o.id}`}
                order={o}
                onEdit={onEditOrder}
                onRemove={onRemoveOrder}
              />
            ))}

          {useSearchAutoComplete &&
            type === 'PickUp' &&
            pickUpSearchType !== 'generalCargo' &&
            orders!.length > 0 &&
            orders.map(o => (
              <GateInOrderListItem
                key={`gate-in-order-list-item-${o.id}`}
                order={o}
                onEdit={onEditOrder}
                onRemove={onRemoveOrder}
              />
            ))}

          {!useSearchAutoComplete
            ? orders.map(o =>
                o.containerNumber === null && o.commodityId !== null ? (
                  <GateInGeneralCargoOrderListItem
                    key={`gate-in-order-list-item-${o.id}`}
                    order={o}
                    onRemove={onRemoveOrder}
                    validateOutboundRequest={validateOutboundRequest}
                  />
                ) : (
                  <OrderListItem
                    key={`order-list-item-${o.id}`}
                    order={o}
                    onEdit={onEditOrder}
                    onRemove={onRemoveOrder}
                  />
                ),
              )
            : ''}

          {/* Empty List */}
          {!orders.length &&
            !nnrOrders?.length &&
            !pickUpGeneralCargoOrders?.length &&
            !dropOffGeneralCargoOrders?.length && <EmptyList />}
        </Box>
      </Box>
    )
  },
)
