import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDamageRecordStep } from '@planning/components'
import { useNavigationStore } from '@planning/stores'
import { NNROrderData } from '@planning/stores/gateClerk/GateOperationViewStore'
import { GateOutViewStore } from '@planning/stores/gateClerk/GateOutViewStore'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog, ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NavigationStackCloseButton } from '../TallymanV2/Components/HeaderComponents/NavigationStackCloseButton'
import { GateOutOrderHandlingBox } from './Components/GateOutOrderHandlingBox'
import { IInspectContainerFormData, InspectContainer } from './Components/InspectContainer'

interface IProps {
  store: GateOutViewStore
}

export interface IGateOutFormData {
  visitId: number
  truckPlate: string
  orders: IInspectContainerFormData[]
  checkOrders: IInspectContainerFormData[]
  notDroppedInboundOrders: IInspectContainerFormData[]
}

export const GateOutForm: FC<IProps> = observer(({ store }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      visitId: store.visit?.id ?? 0,
      truckPlate: store.visit?.identifier ?? '',
      orders: store.orders,
      checkOrders: store.checkedOrders,
      notDroppedInboundOrders: [],
    } as IGateOutFormData,
  })

  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore } = usePlanningStore()
  const navigationStore = useNavigationStore()

  const onFormSubmit = async (data: IGateOutFormData) => {
    store.setGateOutFormData(data)

    if (
      store.allOrders
        .filter(o => o.direction == 'Inbound' || o.commodityId != null)
        .some(o => o.status === 'Open')
    )
      store.openGateOutWithPendenciesConfirmationDialog()
    else onGateOut()
  }

  const onGateOut = async () => {
    try {
      const { notificationStore } = store.parentStore

      if (store.gateOutFormData) {
        const data = store.gateOutFormData
        data.checkOrders = [...store.checkedOrders]
        store.allOrders.forEach(x => {
          if (!data.checkOrders.find(order => order.id === x.id)) {
            data.checkOrders.push(x)
          }
        })

        data.checkOrders = data.checkOrders.filter(
          order => !store.deletedOrderIds.find(id => id === order.id),
        )

        data.notDroppedInboundOrders = [...store.notDroppedInboundOrders]

        await notificationStore.createGateOutRequest(data)
      } else {
        throw new Error('Gate out form data is missing')
      }

      store.reset()
      navigationStore.clear()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const onEditOrder = (order: IInspectContainerFormData) => {
    navigationStore.push(
      <InspectContainer
        order={order}
        damages={order.damagesReported}
        submitButtonText={t('save', 'Save')}
        displayDamageReport
        onSubmit={data => {
          const handleSubmit = (orderData: IInspectContainerFormData) => {
            store.setCheckedOrder(orderData)
            navigationStore.pop()
          }

          if (data.hasDamage) {
            handleReportDamage(data, handleSubmit)
          } else {
            handleSubmit(data)
          }
        }}
      />,
    )
  }

  const handleReportDamage = (
    orderData: IInspectContainerFormData,
    onConfirm: (orderData: IInspectContainerFormData) => void,
  ) => {
    navigationStore.push(
      <ContainerDamageRecordStep
        orderData={orderData}
        containerNumber={orderData?.containerNumber}
        onSubmit={(data, damages) => {
          data.damagesReported = damages
          navigationStore.pop()

          onConfirm(data)
        }}
      />,
    )
  }

  const onCheckGCOrder = (order: IInspectContainerFormData) => store.setCheckedOrder(order)

  const onRemoveOrder = (order: IInspectContainerFormData) => store.openRemoveDialog(order)

  const onRemoveNNROrder = (nnrOrder: NNROrderData) => store.openRemoveDialog(nnrOrder)

  const onToggleNotDroppedInbound = (orderId: number) => store.toggleNotDroppedInboundOrder(orderId)

  if (!store.visit) return <></>

  const GateOutHeader: FC = observer(() => (
    <Header
      title={t('gateOut', 'Gate out')}
      leftRenderOption={<NavigationStackCloseButton onClose={store.openCancelGateDialog} />}
      rightRenderOption={
        <Button
          variant='contained'
          type='submit'
          sx={{
            width: '15%',
            minWidth: '100px',
          }}
          style={
            store.orders.length
              ? {
                  backgroundColor: theme.palette.grey[100],
                  color: theme.palette.grey[500],
                  boxShadow: '0 8px 16px 0 rgba(249, 250, 251, 0.24)',
                }
              : {}
          }
          data-cy='gate-out-submit-btn'
        >
          {t('gateOut', 'Gate out')}
        </Button>
      }
    />
  ))

  const Dialogs: FC = observer(() => {
    const onClose = () => {
      store.reset()
      navigationStore.pop()
    }

    const onConfirmRemoval = () => {
      store.selectedOrder ? deleteOrder() : deleteNNROrder()
      store.resetDialogs()
    }

    const deleteNNROrder = () => {
      const orderIdsToBeDeleted = store.orders
        .filter(o => !o.containerNumber && o.nonNumericOrderId === store.selectedNNROrder?.id)
        .map(o => o.id)

      orderIdsToBeDeleted.forEach(id => store.deleteOrder(id))
    }

    const deleteOrder = () => {
      store.deleteOrder(store.selectedOrder?.id ?? -1)

      store.resetDialogs()
    }

    return (
      <>
        <ConfirmationDialog
          open={store.isRemoveDialogOpen}
          title={`${t('sureYouWantToRemove', 'Sure you want to remove')} ${
            (store.selectedOrder && store.selectedOrder?.containerNumber) ||
            store.selectedNNROrder?.referenceNumber
          }?`}
          primaryActionText={t('remove', 'Remove')}
          closeLabel={t('cancel', 'Cancel')}
          onConfirm={onConfirmRemoval}
          onClose={store.resetDialogs}
          mobile
        />

        <ConfirmationDialog
          open={store.isCancelGateDialogOpen}
          title={t('cancelGateOut?', 'Cancel gate out?')}
          message={t('anyChangesYouMadeWillBeLost', 'Any changes you made will be lost')}
          primaryActionText={t('cancel', 'Cancel')}
          closeLabel={t('noDontCancel', `No, don't cancel`)}
          onConfirm={onClose}
          onClose={store.resetDialogs}
          mobile
        />

        <ConfirmationDialog
          open={store.isGateOutWithPendenciesConfirmationDialogOpen}
          title={t('gateOutConfirmation', 'Gate out confirmation')}
          message={t(
            'truckVisitHasOpenOrdersProceedGateOutQuestion',
            'This Truck Visit still have open orders. Are you sure you want to proceed?',
          )}
          primaryActionText={t('yes', 'Yes')}
          closeLabel={t('no', 'No')}
          onConfirm={onGateOut}
          onClose={store.resetDialogs}
          mobile
        ></ConfirmationDialog>
      </>
    )
  })

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <GateOutHeader />

      <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
        <Box m='1rem 0 !important'>
          <Typography variant='caption'>{t('truckPlateNumber', 'Truck plate number')}</Typography>
          <Typography variant='h4'>{store.visit.identifier ?? ''}</Typography>
        </Box>
        <Controller
          control={control}
          name={`orders`}
          render={() => {
            return (
              <GateOutOrderHandlingBox
                orders={store.orders}
                checkedOrders={store.checkedOrders}
                onRemoveOrder={onRemoveOrder}
                onCheckOrder={onEditOrder}
                onCheckGCOrder={onCheckGCOrder}
                onRemoveNNROrder={onRemoveNNROrder}
                error={!!errors.orders}
              />
            )
          }}
        />

        {store.notFinishedInboundOrders.length > 0 && (
          <Controller
            control={control}
            name={`notDroppedInboundOrders`}
            render={() => {
              return (
                <FormGroup>
                  {store.notFinishedInboundOrders.map(o => {
                    return (
                      <FormControlLabel
                        key={`inbound-order-list-item-${o.containerNumber}`}
                        control={<Checkbox />}
                        label={t(
                          'containerWasNotDroppedOff',
                          'Container {containerNumber} was not dropped off and is still on the truck',
                          {
                            containerNumber: o.containerNumber,
                          },
                        )}
                        onChange={() => onToggleNotDroppedInbound(o.id)}
                      />
                    )
                  })}
                </FormGroup>
              )
            }}
          />
        )}

        <Dialogs />
      </ContainerMobile>
    </form>
  )
})
