import { Box, Card, CardContent, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  ContainerJourneyDto,
  HoldResponseDto,
  OrderResponseDto,
} from '@planning/app/api'
import { ContainerDirectionChip } from '@planning/components/ContainerDirectionChip'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { useTranslate } from '@tolgee/react'
import { HighlightedText } from '@tom-ui/ui'
import moment from 'moment'
import { FC } from 'react'

interface Props {
  containerNumber: string
  holds: HoldResponseDto[]
  highlight?: string
  onClick?: () => void
  children?: React.ReactNode
  direction?: CarrierVisitDirection
  order?: ContainerJourneyDto
  releaseEnd?: string | null
  isOnTerminal?: boolean
}

export const GateClerkSearchResult: FC<Props> = ({
  containerNumber,
  holds,
  highlight,
  onClick,
  children,
  direction,
  order,
  releaseEnd,
  isOnTerminal,
}) => {
  const { tenantStore, gateClerkViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const hasActiveHolds = holds && holds.some(h => h.status === 'Active')
  const isReleaseExpired = !!releaseEnd && moment() > moment(releaseEnd)
  const isValidState =
    ((isOnTerminal === undefined || isOnTerminal) &&
      order?.isOnTerminal !== undefined &&
      !order.isOnTerminal &&
      direction === 'Inbound') ||
    ((tenantStore.allowGateInWhenUnitNotOnTerminal ||
      (order?.isOnTerminal !== undefined && order.isOnTerminal)) &&
      direction === 'Outbound' &&
      !hasActiveHolds &&
      (tenantStore.skipReleaseOrder || !isReleaseExpired))

  const openInboundForADifferentCarrierValidation = (order: ContainerJourneyDto) => {
    return !!gateClerkViewStore.ordersByContainerNumber.find(
      item =>
        item.containerNumber === order.containerNumber &&
        item.direction === CarrierVisitDirection.Inbound &&
        item.carrierVisitId !== null,
    )
  }

  const openOutboundForADifferentCarrierValidation = (
    order: ContainerJourneyDto | OrderResponseDto,
  ) => {
    return !!gateClerkViewStore.pickUpOrderSearchStore.ordersByContainerNumber.find(
      item =>
        item.containerNumber === order.containerNumber &&
        item.direction === CarrierVisitDirection.Outbound &&
        item.carrierVisitId !== null,
    )
  }

  const ActiveHoldsAlert = () => (
    <CustomAlert
      message={t(
        'containerHasHoldsContactControlRoom',
        'Container has holds, contact control room',
      )}
      severity='warning'
    />
  )

  const ReleaseExpiredAlert = () => (
    <CustomAlert
      message={t(
        'containerReleaseHasExpiredContactControlRoom',
        'Container release has expired, contact control room',
      )}
      severity='warning'
    />
  )

  const NotOnTerminalAlert = () => (
    <CustomAlert
      message={
        tenantStore.allowGateInWhenUnitNotOnTerminal
          ? t(
              'unitNotOnTerminalYetButTrucksArePermittedToProceed',
              'Unit (container or trailer) not on terminal yet, but trucks are permitted to proceed',
            )
          : t(
              'containerNotOnTerminalYetContactControlRoom',
              'Container not on terminal yet, contact control room',
            )
      }
      severity='warning'
    />
  )

  const AlreadyOnTerminalAlert = () => (
    <CustomAlert
      message={t(
        'containerIsAlreadyOnTerminalContactControlRoom',
        'Container is already on terminal, contact control room',
      )}
      severity='warning'
    />
  )

  const OpenOrderWithDifferentCarrierAlert = () => (
    <CustomAlert
      message={t(
        'containerHasOpenOrderForADifferentCarrier',
        'Container has an open order for a different carrier',
      )}
      severity='warning'
    />
  )

  return (
    <Card
      sx={{
        mb: '1rem',
      }}
      onClick={() => {
        if (isValidState) onClick?.()
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h3'>
            <HighlightedText highlight={highlight}>{containerNumber}</HighlightedText>
          </Typography>
          {direction ? (
            <ContainerDirectionChip
              direction={direction}
              label={direction === 'Inbound' ? t('dropOff', 'Drop Off') : t('pickUp', 'Pick Up')}
            />
          ) : (
            ''
          )}
        </Box>

        {isValidState && children}

        {order && openInboundForADifferentCarrierValidation(order) && (
          <OpenOrderWithDifferentCarrierAlert />
        )}

        {order && openOutboundForADifferentCarrierValidation(order) && (
          <OpenOrderWithDifferentCarrierAlert />
        )}

        {order?.isOnTerminal !== undefined && order.isOnTerminal && direction == 'Inbound' && (
          <Box mt='0.5rem' mb='0.5rem'>
            <AlreadyOnTerminalAlert />
          </Box>
        )}

        {order?.isOnTerminal !== undefined && !order.isOnTerminal && direction == 'Outbound' && (
          <Box mt='0.5rem' mb='0.5rem'>
            <NotOnTerminalAlert />
          </Box>
        )}

        {hasActiveHolds && (
          <Box mt='0.5rem' mb='0.5rem'>
            <ActiveHoldsAlert />
          </Box>
        )}

        {isReleaseExpired && !tenantStore.skipReleaseOrder && (
          <Box mt='0.5rem' mb='0.5rem'>
            <ReleaseExpiredAlert />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
