import { FormHelperText, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { FieldboxSelectionGroup, FieldboxWithRadio, useMinimalsTheme } from '@tom-ui/ui'
import { DirectLoadOption } from '../../models/job-stepper.model'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { JobInformationOperationalInstructions } from '../JobInformation/JobInformationOperationalInstructions'
import { JobInformationWrapper } from '../JobInformation/JobInformationWrapper'

interface Props {
  job: JobDto
  currentOption?: DirectLoadOption
  currentStep: number
  stepsTotal: number
  hasError?: boolean
  handleChange: (newValue?: DirectLoadOption) => void
}

export const DirectLoadDestination = ({
  job,
  currentOption,
  currentStep,
  stepsTotal,
  hasError,
  handleChange,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleDirectLoad = (value: string) => {
    handleChange(value as DirectLoadOption)
  }

  return (
    <JobInformationWrapper job={job} currentStep={currentStep} stepsTotal={stepsTotal}>
      {(!currentStep || currentStep === 1) && <JobInformationOperationalInstructions job={job} />}
      <JobInformationJourney job={job}>
        <Stack>
          <Typography mb={theme.customSpacing.xs} variant='subtitle1'>
            {t('selectDestination', 'Select destination')}
          </Typography>

          <FieldboxSelectionGroup
            onSelect={handleDirectLoad}
            defaultValue={currentOption}
            options={controller => (
              <>
                <FieldboxWithRadio
                  selectionKey={DirectLoadOption.inbound}
                  controller={controller}
                  label={t(job.to.locationName, job.to.locationName)}
                />
                {!!job.linkedOutboundWorkInstruction && (
                  <FieldboxWithRadio
                    selectionKey={DirectLoadOption.outbound}
                    controller={controller}
                    label={t(
                      job.linkedOutboundWorkInstruction.destination.locationName,
                      job.linkedOutboundWorkInstruction.destination.locationName,
                    )}
                  />
                )}
              </>
            )}
          />

          {hasError && (
            <FormHelperText error sx={{ marginLeft: '14px' }}>
              {t('selectOneOption', 'Select one option')}
            </FormHelperText>
          )}
        </Stack>
      </JobInformationJourney>
    </JobInformationWrapper>
  )
}
