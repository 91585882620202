import { Grid, TextField } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { validateContainerNumber } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'

export const ContainerInfoForm: FC = observer(() => {
  const { t } = useTranslate()
  const { isoCodeMappingInputStore } = usePlanningStore()

  const { register, watch, control, formState } = useFormContext()

  const { errors } = formState

  const containerNumberErrorText = (errors: FieldErrors) => {
    const containerNumberError = errors.number
    if (containerNumberError) {
      const errorType = containerNumberError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate')
        return `${t('mustMatchPattern', 'Must match pattern')}: ABCU1234567`

      return containerNumberError.message?.toString()
    }
  }

  return (
    <>
      <Grid item sm={6} xs={12}>
        <TextField
          data-cy='container-number-field'
          fullWidth
          label={`${t('containerNumber', 'Container Number')}`}
          variant='outlined'
          required
          type='text'
          {...register('number', {
            required: true,
            validate: v => !!v && validateContainerNumber(v),
          })}
          onChange={event => (event.target.value = event.target.value.toUpperCase())}
          name='number'
          error={!!errors.number}
          helperText={containerNumberErrorText(errors)}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          data-cy='container-operator-field'
          fullWidth
          required
          label={`${t('operator', 'Operator')}`}
          variant='outlined'
          type='text'
          {...register('operator', { required: true })}
          name='operator'
        />
      </Grid>

      <Grid item xs={12}>
        {isoCodeMappingInputStore.renderIsoCodeMappingInput?.({
          label: `${t('isoCode', 'ISO Code')}`,
          fullWidth: true,
          variant: 'outlined',
          type: 'text',
          uppercase: true,
          formState: formState,
          name: 'isoCode',
          required: true,
          control: control,
          watch: watch,
        })}
      </Grid>
    </>
  )
})
