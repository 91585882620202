import { ContainerStatus } from '@storage/app/api'
import { ContainerTurnoverStore } from '@storage/stores/container-turnover.store'
import { IReactionDisposer, reaction } from 'mobx'
import { ContainerTurnoverFilterFormMapper } from '../components/container-turnovers-filter-form'
import { ContainerTurnoversFilterStore } from './container-turnovers-filter.store'
import { ContainerTurnoversPaginationStore } from './container-turnovers-pagination.store'

export class ContainerTurnoversListStore {
  constructor(
    private readonly _containerTurnoverStore: ContainerTurnoverStore,
    private readonly _containerTurnoversFilterStore: ContainerTurnoversFilterStore,
    private readonly _containerTurnoversPaginationStore: ContainerTurnoversPaginationStore,
  ) {}

  public loadContainersEffect(isContainerTurnoversPage: boolean): IReactionDisposer {
    return reaction(
      () => ({
        filter: this._containerTurnoversFilterStore.filter,
        pagination: this._containerTurnoversPaginationStore.pagination,
      }),
      ({ filter, pagination }) => {
        this._containerTurnoverStore.loadAll(
          ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
            filter,
            isContainerTurnoversPage,
          ),
          pagination,
        )
      },
      { fireImmediately: true },
    )
  }

  public async reload(isContainerTurnoversPage: boolean): Promise<void> {
    await this._containerTurnoverStore.loadAll(
      ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
        this._containerTurnoversFilterStore.filter,
        isContainerTurnoversPage,
      ),
      this._containerTurnoversPaginationStore.pagination,
    )
  }

  public loadEffectForContainersOnTerminal(isContainerTurnoversPage: boolean) {
    return reaction(
      () => ({
        filter: this._containerTurnoversFilterStore.filter,
        pagination: this._containerTurnoversPaginationStore.pagination,
      }),
      ({ filter, pagination }) => {
        this._containerTurnoverStore.loadAll(
          ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
            {
              ...filter,
              locationStatus: ContainerStatus.OnTheYard,
            },
            isContainerTurnoversPage,
          ),
          pagination,
        )
      },
      { fireImmediately: true },
    )
  }
}
