import { useOperationsStore } from '@operations/AppProvider'
import { EquipmentPlanningEquipmentsUIStore } from '@operations/features/equipmentPlanning/stores/equipment-planning-equipments-ui-store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { SelectCranes } from '../components/SelectCranes/SelectCranes'
import { CraneSplitContainerUIStore } from '../stores/crane-split-container.ui-store'
import { CraneSplitMessageUIStore } from '../stores/crane-split-message.ui-store'
import { SelectCranesDialogUIStore } from '../stores/select-cranes-dialog.ui-store'

interface Props {
  uiStore: SelectCranesDialogUIStore
  uiMessageStore: CraneSplitMessageUIStore
  uiContainerStore: CraneSplitContainerUIStore
  uiEquipmentPlanningStore: EquipmentPlanningEquipmentsUIStore
}

export const SelectCranesDialog = observer(
  ({ uiStore, uiMessageStore, uiContainerStore, uiEquipmentPlanningStore }: Props) => {
    const navigate = useNavigate()
    const { appStore } = useOperationsStore()
    const { t } = useTranslate()

    const handleClose = () => {
      if (uiStore.redirectOnCancel) {
        const url = `/vessel-visits/${uiStore.vesselId}/dashboard`

        navigate(url)
      } else {
        uiStore.toggle()
      }
    }

    const handlePlan = async (cranes: number[]) => {
      uiMessageStore.setMessage()

      await uiStore.plan(cranes)
      if (uiContainerStore.initialCraneIds.length) {
        uiMessageStore.setMessage(
          t(
            'pleaseTakeALookAtTheEquipmentPlanning',
            'Please take a look at the Equipment Planning if you already planned because the "Edit Cranes" resets the planning',
          ),
          'warning',
        )
      }

      appStore.setShowAlert(
        'success',
        t('craneSplitGenerated', 'Crane split generated successfully'),
      )

      uiContainerStore.initInitialCranes()
      uiContainerStore.clearWorkQueueStack()
    }

    return (
      <SelectCranes
        selectedCranes={uiStore.selectedCranes}
        craneOptions={uiStore.cranes}
        equipmentIdsInUse={uiEquipmentPlanningStore.equipmentIdsOnUseForOtherVistis}
        berths={uiStore.vesselVisit?.berthNames ?? []}
        side={uiStore.vesselVisit?.berthSide}
        existingCraneSplitBerthSide={uiStore.vesselVisit?.craneSplitBerthSide}
        isOpen={uiStore.dialog}
        hasWorkInstructions={uiStore.hasWorkInstructions}
        onPlan={handlePlan}
        onClose={handleClose}
      />
    )
  },
)
