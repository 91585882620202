import { TenantResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetTenantQuery } from '@planning/messages/queries/getTenantQueryHandler'
import { action, computed, makeObservable, observable } from 'mobx'

export class TenantStore {
  tenant?: TenantResponseDto

  private hasBeenLoaded = false

  constructor(private messageBus: IMessageBus) {
    makeObservable(this, {
      tenant: observable,
      setTenant: action,
      configs: computed,
      skipVesselTally: computed,
      skipVesselVisitReadyForOperation: computed,
      skipRailTallyLoadPreparation: computed,
      skipReleaseOrder: computed,
      skipBerthTimestamp: computed,
      hasDamageCatalogue: computed,
    })

    messageBus.subscribeEvent(GetTenantQuery.type, this.receiveTenantEventMessage)
    messageBus.subscribeEvent(EventTypes.TenantUpsertedEvent, this.receiveTenantEventMessage)
  }

  setTenant = (tenant: TenantResponseDto) => {
    this.tenant = tenant
  }

  get configs() {
    return this.tenant?.configs
  }

  get skipVesselTally() {
    return this.configs?.skipVesselTally ?? false
  }

  get skipRailTally() {
    return this.configs?.skipRailTally ?? false
  }

  get skipRailTallyLoadPreparation() {
    return this.configs?.skipRailTallyLoadPreparation ?? false
  }

  get skipVesselVisitReadyForOperation() {
    return this.configs?.skipVesselVisitReadyForOperation ?? false
  }

  get skipYardPlanning() {
    return this.configs?.skipYardPlanning ?? false
  }

  get skipReleaseOrder() {
    return this.configs?.skipReleaseOrder ?? false
  }

  get skipBerthTimestamp() {
    return this.configs?.skipBerthTimestamp ?? false
  }

  get hasGeneralCargo() {
    return this.configs?.hasGeneralCargo ?? false
  }

  get hasDamageCatalogue() {
    return this.configs?.hasDamageCatalogue ?? false
  }

  get allowGateInWhenUnitNotOnTerminal() {
    return this.configs?.allowGateInWhenUnitNotOnTerminal ?? false
  }

  fetch = async () => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(new GetTenantQuery())
  }

  receiveTenantEventMessage = (event: IEvent<TenantResponseDto>) => {
    this.setTenant(event.payload)
  }
}
