import { Box, Grid, Typography } from '@mui/material'
import { ContainerTurnoversListItem } from '@storage/components/container-turnovers-list/ContainerTurnoversListItem'
import { Pagination } from '@storage/components/pagination'
import { useStores } from '@storage/hooks/use-stores.hook'
import { defaultValues } from '@storage/pages/container-turnovers/components/container-turnovers-filter-form'
import { useContainerTurnoversStores } from '@storage/pages/container-turnovers/hooks/container-turnovers-stores.hook'
import { useTranslate } from '@tolgee/react'
import { BackButton, useMinimalsTheme } from '@tom-ui/ui'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ContainerTurnoversSearchInput } from './components/ContainerTurnoversSearchInput'
import { useContainerTurnoverPositionUpdate } from './hooks/use-container-turnover-position-update'

const OperatorContainerTurnoversList = observer(() => {
  const { t } = useTranslate()
  const { containerTurnoverStore, operatorContainerTurnoverStore } = useStores()
  const location = useLocation()
  const navigate = useNavigate()

  const {
    containerTurnoversFilterStore,
    containerTurnoversPaginationStore,
    containerTurnoversListStore,
    manualInputValidationStore,
  } = useContainerTurnoversStores()

  useEffect(() => {
    const dispose = containerTurnoversListStore.loadEffectForContainersOnTerminal(false)

    return () => dispose()
  }, [containerTurnoversListStore, manualInputValidationStore])

  const { breakpoints } = useMinimalsTheme()

  const [containerOrReferenceNumber, setContainerOrReferenceNumber] = useState('')

  useEffect(() => {
    const debouncedSetFilter = debounce(() => {
      containerTurnoversFilterStore.setFilter({
        ...defaultValues,
        containerOrReferenceNumber,
      })
    }, 500)

    debouncedSetFilter()

    return () => debouncedSetFilter.cancel()
  }, [containerOrReferenceNumber, containerTurnoversFilterStore])

  useEffect(() => {
    if (location.state) {
      operatorContainerTurnoverStore.setPreviousPageLocation(location.state.key)
    }
  }, [location.state, operatorContainerTurnoverStore])

  const navigateToContainerTurnoverPositionUpdate = useContainerTurnoverPositionUpdate()

  const handleGoBack = () => {
    const index = operatorContainerTurnoverStore.previousPageLocation.indexOf('/Operator')
    navigate(operatorContainerTurnoverStore.previousPageLocation.slice(index))
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        p={3}
        sx={{ height: '100%', overflowY: 'auto', minWidth: '400px' }}
      >
        <Grid
          item
          xs
          sx={{
            position: 'sticky',
            top: 0,
            alignSelf: 'start',
            [breakpoints.down('lg')]: {
              position: 'relative',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <BackButton tooltip={t('return', 'Return')} onGoBack={handleGoBack} />
            <Box width={'100%'}>
              <ContainerTurnoversSearchInput
                value={containerOrReferenceNumber}
                onChange={setContainerOrReferenceNumber}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1, mb: 4 }}>
            {containerTurnoverStore.entries.length > 0 ? (
              containerTurnoverStore.entries.map(containerTurnover => (
                <ContainerTurnoversListItem
                  key={containerTurnover.id}
                  item={containerTurnover}
                  variation='compact'
                  clickable
                  onClick={() => navigateToContainerTurnoverPositionUpdate(containerTurnover.id)}
                />
              ))
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Typography>{t('noDataAvailable', 'No data available')}</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Pagination
        paginatedEntityStore={containerTurnoverStore}
        paginationStore={containerTurnoversPaginationStore}
      />
    </>
  )
})

export default OperatorContainerTurnoversList
