import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { JobStore } from '@operations/stores/JobStore'
import { AppStore } from '@tom-ui/utils'
import { action, computed, makeObservable, observable } from 'mobx'
import { JobSignalRDto } from '../models/job-signalR-event.model'
import { EquipmentOperatorNotificationUIStore } from './equipment-operator-notification.ui-store'
import { EquipmentOperatorStepperUIStore } from './equipment-operator-stepper.ui-store'
import { EquipmentOperatorWorkInstructionsUIStore } from './equipment-operator-work-instructions.ui-store'
import { EquipmentOperatorUIStore } from './equipment-operator.ui-store'
import { JobHandler } from './job-signalr-strategies/JobHandler'
import { WorkInstructionHandler } from './job-signalr-strategies/WorkInstructionHandler'

export class EquipmentOperatorSignalRUIStore {
  loadingSignalR = false

  signalREventsQueue: JobSignalRDto[] = []

  constructor(
    private messageBus: IMessageBus,
    private jobStore: JobStore,
    private appStore: AppStore,
    private equipmentOperatorNotificationUIStore: EquipmentOperatorNotificationUIStore,
    private equipmentOperatorUIStore: EquipmentOperatorUIStore,
    private equipmentOperatorWorkInstructionsUIStore: EquipmentOperatorWorkInstructionsUIStore,
    private equipmentOperatorStepperUIStore: EquipmentOperatorStepperUIStore,
  ) {
    makeObservable(this, {
      loadingSignalR: observable,

      toggleLoadingSignalR: action,

      jobSignalRStrategy: computed,
    })

    this.messageBus.subscribeEvent(EventTypes.CarrierVisitRefresh, message => {
      this.enqueueSignalREvent(message, this.refreshVesselVisit)
    })

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionDeleted, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.deleteWorkInstructions)
    })

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionFinished, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.finishWorkInstruction)
    })

    this.messageBus.subscribeEvent(EventTypes.EquipmentsJobRefresh, message => {
      this.enqueueSignalREvent(message, this.refreshEquipment)
    })

    this.messageBus.subscribeEvent(EventTypes.JobUpserted, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.upsertJob)
    })

    this.messageBus.subscribeEvent(EventTypes.WagonWeightUpdated, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.updateWagonWeight)
    })

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionJobsUpserted, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.upsertWorkInstructionJobs)
    })

    this.messageBus.subscribeEvent(EventTypes.EquipmentLastPositionOnYard, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.updateEquipmentPositionOnYard)
    })

    this.messageBus.subscribeEvent(EventTypes.CoolingOrderUpserted, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.updateContainerPlugInfo)
    })

    this.messageBus.subscribeEvent(EventTypes.CarrierVisitWorkInstructionUpserted, message => {
      this.enqueueSignalREvent(message, this.jobSignalRStrategy.updateCarrierVisitWorkInstruction)
    })
  }

  enqueueSignalREvent = async (eventMessage: any, eventHandler: any) => {
    this.signalREventsQueue.push({
      eventMessage,
      eventHandler,
    })

    if (this.signalREventsQueue.length === 1) {
      await this.processSignalRQueue()
    }
  }

  processSignalRQueue = async () => {
    if (!this.signalREventsQueue.length) {
      return
    }

    const firstEvent = this.signalREventsQueue[0]

    await firstEvent.eventHandler(firstEvent.eventMessage)

    this.signalREventsQueue.shift()

    await this.processSignalRQueue()
  }

  refreshVesselVisit = async () => {
    if (
      !this.equipmentOperatorUIStore.selectedEquipmentId &&
      this.equipmentOperatorUIStore.isContainerOperation
    )
      return

    if (!this.loadingSignalR) {
      this.toggleLoadingSignalR(true)

      await this.jobSignalRStrategy.refreshVesselVisit()

      this.toggleLoadingSignalR(false)
    }
  }

  refreshEquipment = async (res: IEvent<number[]>) => {
    if (!this.equipmentOperatorUIStore.selectedEquipmentId) return

    if (
      !this.loadingSignalR &&
      res.payload?.includes(this.equipmentOperatorUIStore.selectedEquipmentId)
    ) {
      this.toggleLoadingSignalR(true)

      this.jobSignalRStrategy.refreshJobs()

      this.toggleLoadingSignalR(false)
    }
  }

  toggleLoadingSignalR(value: boolean) {
    this.loadingSignalR = value
  }

  get jobSignalRStrategy() {
    return this.equipmentOperatorUIStore.isContainerOperation
      ? new JobHandler(
          this.jobStore,
          this.appStore,
          this.equipmentOperatorNotificationUIStore,
          this.equipmentOperatorUIStore,
          this.equipmentOperatorStepperUIStore,
        )
      : new WorkInstructionHandler(
          this.appStore,
          this.equipmentOperatorNotificationUIStore,
          this.equipmentOperatorWorkInstructionsUIStore,
        )
  }
}
