import { ContainerTurnoversFilterDto, UnitType } from '@storage/app/api'
import { AllSelectOption, BooleanSelectOption } from '@storage/app/models/select-option.model'
import { UtilityMappers } from '@storage/utils/mappers'
import {
  ContainerTurnoversFilterFormProfile,
  defaultValues,
} from './container-turnovers-filter-form.profile'

const mapBooleanToBooleanSelectOption = (
  value: boolean | null | undefined,
): BooleanSelectOption | AllSelectOption =>
  value == null ? 'All' : value ? BooleanSelectOption.True : BooleanSelectOption.False

export const mapFormValuesToFilterDto = (
  formValues: ContainerTurnoversFilterFormProfile,
  isContainerTurnoversPage: boolean,
): ContainerTurnoversFilterDto => ({
  ...formValues,
  inboundCarrierType: UtilityMappers.mapOptionalFieldValueToString(formValues.inboundCarrierType),
  outboundCarrierType: UtilityMappers.mapOptionalFieldValueToString(formValues.outboundCarrierType),
  inboundCarrier: UtilityMappers.mapOptionalFieldValueToNumber(formValues.inboundCarrier),
  outboundCarrier: UtilityMappers.mapOptionalFieldValueToNumber(formValues.outboundCarrier),
  size: UtilityMappers.mapOptionalFieldValueToNumber(formValues.size),
  locationStatus: UtilityMappers.mapOptionalFieldValueToString(formValues.locationStatus),
  isEmpty: UtilityMappers.mapOptionalFieldValueToBoolean(formValues.isEmpty),
  isReefer: UtilityMappers.mapOptionalFieldValueToBoolean(formValues.isReefer),
  isDamaged: UtilityMappers.mapOptionalFieldValueToBoolean(formValues.isDamaged),
  isRestow: UtilityMappers.mapOptionalFieldValueToBoolean(formValues.isRestow),
  isDangerous: UtilityMappers.mapOptionalFieldValueToBoolean(formValues.isDangerous),
  containerType: UtilityMappers.mapOptionalFieldValueToString(formValues.containerType),
  containerHeight: UtilityMappers.mapOptionalFieldValueToString(formValues.containerHeight),
  isInTerminal: UtilityMappers.mapOptionalFieldValueToBoolean(formValues.isInTerminal),
  containerUnitType: isContainerTurnoversPage
    ? [UnitType.Container, UnitType.SwapBody]
    : [UnitType.Container, UnitType.SwapBody, UnitType.Trailer],
})

export const trimFormValues = (
  formValues: ContainerTurnoversFilterFormProfile,
): ContainerTurnoversFilterFormProfile => ({
  ...formValues,
  containerOrReferenceNumber: formValues.containerOrReferenceNumber.trim(),
  containerIsoCode: formValues.containerIsoCode.trim(),
  containerOperator: formValues.containerOperator.trim(),
})

export const mapContainerTurnoversFilterDtoToFormProfile = (
  containerTurnoversFilterDto: ContainerTurnoversFilterDto,
): ContainerTurnoversFilterFormProfile => ({
  containerOrReferenceNumber:
    containerTurnoversFilterDto.containerOrReferenceNumber ??
    defaultValues.containerOrReferenceNumber,
  inboundCarrierType:
    containerTurnoversFilterDto.inboundCarrierType ?? defaultValues.inboundCarrierType,
  outboundCarrierType:
    containerTurnoversFilterDto.outboundCarrierType ?? defaultValues.outboundCarrierType,
  inboundCarrier:
    containerTurnoversFilterDto.inboundCarrier?.toString() ?? defaultValues.inboundCarrier,
  outboundCarrier:
    containerTurnoversFilterDto.outboundCarrier?.toString() ?? defaultValues.outboundCarrier,
  isEmpty: mapBooleanToBooleanSelectOption(containerTurnoversFilterDto.isEmpty),
  size: containerTurnoversFilterDto.size?.toString() ?? defaultValues.size,
  containerIsoCode: containerTurnoversFilterDto.containerIsoCode ?? defaultValues.containerIsoCode,
  typeCode: containerTurnoversFilterDto.typeCode ?? defaultValues.typeCode,
  portOfLoading: containerTurnoversFilterDto.portOfLoading ?? defaultValues.portOfLoading,
  portOfDischarge: containerTurnoversFilterDto.portOfDischarge ?? defaultValues.portOfDischarge,
  weightClasses: containerTurnoversFilterDto.weightClasses ?? defaultValues.weightClasses,
  isReefer: mapBooleanToBooleanSelectOption(containerTurnoversFilterDto.isReefer),
  isDamaged: mapBooleanToBooleanSelectOption(containerTurnoversFilterDto.isDamaged),
  imoClasses: containerTurnoversFilterDto.imoClasses ?? defaultValues.imoClasses,
  isRestow: mapBooleanToBooleanSelectOption(containerTurnoversFilterDto.isRestow),
  containerOperator:
    containerTurnoversFilterDto.containerOperator ?? defaultValues.containerOperator,
  isDangerous: mapBooleanToBooleanSelectOption(containerTurnoversFilterDto.isDangerous),
  locationStatus: containerTurnoversFilterDto.locationStatus ?? defaultValues.locationStatus,
  containerType: containerTurnoversFilterDto.containerType ?? defaultValues.containerType,
  containerHeight: containerTurnoversFilterDto.containerHeight ?? defaultValues.containerHeight,
  isInTerminal: mapBooleanToBooleanSelectOption(containerTurnoversFilterDto.isInTerminal),
})

export const mapFormValuesToQueryParams = (
  formValues: ContainerTurnoversFilterFormProfile,
): Record<keyof ContainerTurnoversFilterFormProfile, string> => ({
  ...formValues,
  imoClasses: UtilityMappers.mapArrayToQueryParams(formValues.imoClasses, 'imoClass'),
  weightClasses: UtilityMappers.mapArrayToQueryParams(formValues.weightClasses, 'weightClasses'),
})

export const mapQueryParamsToFilterDto = (
  queryParams: Record<string, string>,
): ContainerTurnoversFilterDto => {
  return {
    ...mapFormValuesToFilterDto(queryParams as any, false),
    imoClasses: queryParams['imoClasses']
      .split('&')
      .map(imoClassParam => imoClassParam.split('=')[1])
      .filter(value => !!value),
    weightClasses: queryParams['weightClasses']
      .split('&')
      .map(weightClassesParam => weightClassesParam.split('=')[1])
      .filter(value => !!value),
  }
}
