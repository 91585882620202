import { Box, Typography } from '@mui/material'
import { NonNumeric } from './RoutingInfo'
import Label from './label'

interface RoutingInfoProps {
  blockName?: string
  containerNumber?: string
  action: string
  isNonNumeric?: boolean
  nonNumeric?: NonNumeric
}

const LocationInfo = ({
  blockName,
  containerNumber,
  action,
  nonNumeric,
  isNonNumeric = false,
}: RoutingInfoProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {isNonNumeric ? (
        <>
          <Box>
            <Typography variant='body1'>
              {nonNumeric?.pickUpAmount} x {nonNumeric?.shippingLine}
            </Typography>
            <Typography variant='body1'>
              {nonNumeric?.containerHeight && nonNumeric.containerLength
                ? nonNumeric?.containerHeight + ', '
                : nonNumeric?.containerHeight}
              {nonNumeric?.containerLength && `${nonNumeric?.containerLength}'`}
            </Typography>
          </Box>
          <Label color='info'>
            <Typography variant='caption'>{action}</Typography>
          </Label>
        </>
      ) : (
        <>
          <Box>
            <Typography variant='h3' style={{ whiteSpace: 'pre-line' }}>
              {blockName}
            </Typography>
            <Typography variant='body1'>{containerNumber}</Typography>
          </Box>
          <Label color='info'>
            <Typography variant='caption'>{action}</Typography>
          </Label>
        </>
      )}
    </Box>
  )
}

export default LocationInfo
