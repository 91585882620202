import { useTranslate } from '@tolgee/react'
import {
  GateInForm,
  GateOutForm,
  IGateInFormData,
  orderService,
  railVisitService,
  SearchArrivedTruckVisit,
  SelectOperation,
  truckVisitService,
  useNavigationStore,
  usePlanningStore,
  vesselVisitService,
} from '@tom-ui/planning'
import {
  CarrierType,
  CarrierVisitStatus,
  OrderResponseDto,
  RailVisitResponseDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@tom-ui/planning/app/api'
import { OutboundRequestValidationControl } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { OutboundOrderValidationDto } from 'modules/planning/src/stores/gateControl/ValidateOutboundDto'
import { useCallback, useEffect } from 'react'
import { GateInRouteInfoPage } from './GateInRouteInfoPage'

interface NonNumeric {
  pickUpAmount: number
  containerHeight: string
  containerLength: number
  shippingLine: string
}

export const GateClerkHome = observer(() => {
  const { t } = useTranslate()
  const { gateClerkViewStore, containerDamageReportViewStore } = usePlanningStore()
  const { notificationStore } = gateClerkViewStore
  const navigationStore = useNavigationStore()

  useEffect(() => {
    const fetchData = async () => {
      await containerDamageReportViewStore.fetch()
    }

    fetchData()
  }, [containerDamageReportViewStore])

  const getLinkedOrderArrivedOrInOperationVisitName = async (
    order: OrderResponseDto,
    isPickUp?: boolean,
  ) => {
    let arrivedOrInOperationVisitName

    if (order.linkedOrderId || isPickUp) {
      const linkedOrder: OrderResponseDto = order.linkedOrderId
        ? await orderService.getById(order.linkedOrderId)
        : await orderService.getById(order.id)
      if (!linkedOrder || !linkedOrder.carrierVisitId) return arrivedOrInOperationVisitName
      if (linkedOrder.carrierVisitType === CarrierType.Vessel) {
        const vesselVisit: VesselVisitDto = await vesselVisitService.getById(
          linkedOrder.carrierVisitId,
        )
        if (
          vesselVisit.status === CarrierVisitStatus.Arrived ||
          vesselVisit.status === CarrierVisitStatus.InOperation
        ) {
          arrivedOrInOperationVisitName = vesselVisit.identifier
            ? `${t('vessel', 'vessel')}: ${vesselVisit.identifier}`
            : undefined
        }
      }
      if (linkedOrder.carrierVisitType === CarrierType.Truck) {
        const { data: truckVisits }: { data: TruckVisitDto[] } = await truckVisitService.getByIds([
          linkedOrder.carrierVisitId,
        ])
        if (truckVisits[0].ata && !truckVisits[0].atd) {
          arrivedOrInOperationVisitName = `${t('truck', 'truck')}: ${truckVisits[0].identifier}`
        }
      }
      if (linkedOrder.carrierVisitType === CarrierType.Train) {
        const railVisit: RailVisitResponseDto = await railVisitService.getById(
          linkedOrder.carrierVisitId,
        )
        if (
          railVisit.status === CarrierVisitStatus.Arrived ||
          railVisit.status === CarrierVisitStatus.InOperation
        ) {
          const railVisit: RailVisitResponseDto = await railVisitService.getById(
            linkedOrder.carrierVisitId,
          )
          arrivedOrInOperationVisitName = `${t('train', 'Train')}: ${railVisit.identifier} ${linkedOrder.railTrackName}-${t('no', 'no')}.${linkedOrder.waggon} `
        }
      }
    }
    return arrivedOrInOperationVisitName
  }

  const populateGateOutFormData = async (visit: any) => {
    const { gateOutViewStore } = gateClerkViewStore
    gateOutViewStore.setVisit(visit)
    await gateOutViewStore.fetch()
  }

  const onSelectTruckVisitForGateOut = async (visit: any) => {
    await populateGateOutFormData(visit)

    gateClerkViewStore.arrivedTruckVisitSearchStore.setFilter('')

    navigationStore.push(<GateOutForm store={gateClerkViewStore.gateOutViewStore} />)
  }

  const onGateInFormSubmit = async (data: IGateInFormData) => {
    await notificationStore.createGateInRequest(data)

    gateClerkViewStore.gateInViewStore.reset()

    navigationStore.clear()
  }

  const goToRouteInfoPage = async (data: IGateInFormData) => {
    const dropOffs: {
      containerNumber: string
      linkedOrderArrivedOrInOperationVisitName: string | undefined
    }[] =
      (await Promise.all(
        gateClerkViewStore.gateInViewStore.dropOffOrders.map(async o => {
          return {
            containerNumber: o.containerNumber!,
            linkedOrderArrivedOrInOperationVisitName:
              await getLinkedOrderArrivedOrInOperationVisitName(o),
          }
        }),
      )) ?? []
    const pickUps: {
      containerNumber: string
      linkedOrderArrivedOrInOperationVisitName: string | undefined
    }[] =
      (await Promise.all(
        gateClerkViewStore.gateInViewStore.pickUpOrders.map(async o => {
          return {
            containerNumber: o.containerNumber!,
            linkedOrderArrivedOrInOperationVisitName:
              await getLinkedOrderArrivedOrInOperationVisitName(o, true),
          }
        }),
      )) ?? []

    const nonNumeric: NonNumeric[] =
      gateClerkViewStore.gateInViewStore.nnrOrders.map(
        (o: any) =>
          ({
            pickUpAmount: o.pickUpAmount,
            containerHeight: o.containerHeight,
            containerLength: o.containerLength,
            shippingLine: o.shippingLine,
          }) as NonNumeric,
      ) ?? []

    navigationStore.push(
      <GateInRouteInfoPage
        dropOffs={dropOffs}
        pickUps={pickUps}
        nonNumeric={nonNumeric}
        onSubmit={async () => await onGateInFormSubmit(data)}
        onClose={() => navigationStore.pop()}
      />,
    )
  }

  const onSelectOperationNext = (operation: any) => {
    if (operation === 'Inbound')
      navigationStore.push(
        <GateInForm store={gateClerkViewStore.gateInViewStore} onSubmit={goToRouteInfoPage} />,
      )
    else {
      gateClerkViewStore.arrivedTruckVisitSearchStore.setFilter('')
      navigationStore.push(
        <SearchArrivedTruckVisit
          store={gateClerkViewStore.arrivedTruckVisitSearchStore}
          onClick={onSelectTruckVisitForGateOut}
        />,
      )
    }
  }

  gateClerkViewStore.gateInViewStore.validateOutboundRequest = useCallback(
    (params: OutboundOrderValidationDto): React.ReactElement => (
      <OutboundRequestValidationControl
        isOutbound={params.isOutbound}
        customerId={params.customerId}
        commodityId={params.commodityId}
        lotNumber={params.lotNumber}
        packageId={params.packageId}
        quantity={params.quantity}
        unitIds={params.unitIds}
        imoClasses={params.imoClasses}
        hideSuccessfulValidation
      />
    ),
    [],
  )

  return <SelectOperation store={gateClerkViewStore} navigateNext={onSelectOperationNext} />
})
